import { Box, Typography } from '@mui/material'
import React from 'react'

import styles from './LicenseMetric.module.sass'

import variables from '../../../../variables.module.scss'

import ErrorIcon from '@mui/icons-material/Error'

interface LicenseMetricProps {
  title: string | JSX.Element
  value?: number | string | JSX.Element
  icon: JSX.Element
  horizontalValueElement?: JSX.Element
  error?: boolean
}

export const LicenseMetric: React.FC <LicenseMetricProps> = ({ title, value, icon, horizontalValueElement: horizontalElement, error }) => {
  return (
    <Box className={styles.LicenseMetricContainer} bgcolor={error ? variables.lightUpgradeColor : variables.dataBackgroundColor}>
      <Box display='flex' alignItems='center' gap='10px' width='100%'>
        {icon}
        <Box display='flex' alignItems='center' gap='5px' width='100%'>
          <Typography className={styles.LicenseMetricTitle}>
            <span style={{ fontWeight: 600 }}>{title}{value !== undefined ? `: ` : null}</span>
          </Typography>
          {value !== undefined && (typeof value === 'number' || typeof value === 'string') ? (
            <span style={{ color: variables.fontColor }}>{value}</span>
          ) : value}
          {error && <Box display='flex' justifyContent='flex-end' flex='1'>
            <ErrorIcon fontSize='medium' htmlColor={variables.upgradeColor} />
          </Box>}
        </Box>
      </Box>
      {horizontalElement}
    </Box>
  )
}
