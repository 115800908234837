import React from 'react'
import { Box } from '@mui/material'
import variables from '../../../../variables.module.scss'
import styles from '../ScriptingView.module.sass'

interface SidebarScriptGroupProps {
  title: string
  icon: React.ReactNode
}

export const SidebarScriptGroup: React.FC<SidebarScriptGroupProps> = ({ title, icon }) => {
  return (
    <Box display='flex' alignItems='center' gap='10px'>
      <Box flex='1' width='40px' height='1px' bgcolor={variables.lightGrayColor} />
      <p className={styles.SidebarScriptGroup} style={{ flex: 1 }}>
        {icon}
        <span>{title}</span>
      </p>
      <Box flex='1' width='40px' height='1px' bgcolor={variables.lightGrayColor} />
    </Box>
  )
}
