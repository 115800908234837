import React from 'react'
import { Box, Divider, Skeleton, Stack, Typography } from '@mui/material'
import variables from '../../variables.module.scss'

export const EntryDetailedSkeleton = () => {
  return (
    <>
      <Skeleton animation="wave" variant="rectangular" width={240} height={24} sx={{ ml: '5px', mb: '10px', borderRadius: '8px', bgcolor: variables.lightGrayBlueColor }} />
      <Skeleton animation="wave" variant="rectangular" width='100%' height={47} sx={{ mb: '10px', borderRadius: '4px', bgcolor: variables.lightGrayBlueColor }} />
      <Skeleton animation="wave" variant="rectangular" width='100%' height={32} sx={{ mt: '10px', mb: '10px', borderRadius: '4px', bgcolor: variables.lightGrayBlueColor }} />

      <Stack direction="row" spacing={2} mx={3} my={2} mt={4}>
        <Skeleton animation="wave" variant="text" width={80} sx={{ bgcolor: variables.lightGrayBlueColor }} />
        <Skeleton animation="wave" variant="text" width={80} sx={{ bgcolor: variables.lightGrayBlueColor }} />
        <Skeleton animation="wave" variant="text" width={80} sx={{ bgcolor: variables.lightGrayBlueColor }} />
        <Skeleton animation="wave" variant="text" width={80} sx={{ bgcolor: variables.lightGrayBlueColor }} />
        <Skeleton animation="wave" variant="text" width={80} sx={{ bgcolor: variables.lightGrayBlueColor }} />
      </Stack>

      <Divider sx={{ borderColor: variables.dataBackgroundColor }} />

      <Box mt={2} mx={3}>
        <Typography variant="h6" gutterBottom>
          <Skeleton animation="wave" width={100} sx={{ bgcolor: variables.lightGrayBlueColor }} />
        </Typography>

        <Stack spacing={1} mb={2}>
          <Skeleton animation="wave" variant="text" width={150} sx={{ bgcolor: variables.lightGrayBlueColor }} />
          <Skeleton animation="wave" variant="text" width={75} sx={{ bgcolor: variables.lightGrayBlueColor }} />
          <Skeleton animation="wave" variant="text" width={300} sx={{ bgcolor: variables.lightGrayBlueColor }} />
        </Stack>
      </Box>

      <Divider sx={{ borderColor: variables.dataBackgroundColor }} />

      <Box my={2} mx={3}>
        <Box mt={2}>
          <Typography variant="subtitle1" gutterBottom>
            <Skeleton animation="wave" width={150} sx={{ bgcolor: variables.lightGrayBlueColor }} />
          </Typography>
          <Stack direction="row" spacing={2}>
            <Skeleton animation="wave" variant="text" width={50} sx={{ bgcolor: variables.lightGrayBlueColor }} />
            <Skeleton animation="wave" variant="text" width={100} sx={{ bgcolor: variables.lightGrayBlueColor }} />
            <Skeleton animation="wave" variant="text" width={150} sx={{ bgcolor: variables.lightGrayBlueColor }} />
            <Skeleton animation="wave" variant="text" width={100} sx={{ bgcolor: variables.lightGrayBlueColor }} />
          </Stack>
        </Box>
      </Box>

      <Divider sx={{ borderColor: variables.dataBackgroundColor }} />

      <Box mt={2} mx={3}>
        <Typography variant="h6" gutterBottom>
          <Skeleton animation="wave" width={100} sx={{ bgcolor: variables.lightGrayBlueColor }} />
        </Typography>

        <Stack spacing={1} mb={2}>
          <Skeleton animation="wave" variant="text" width={150} sx={{ bgcolor: variables.lightGrayBlueColor }} />
          <Skeleton animation="wave" variant="text" width={300} sx={{ bgcolor: variables.lightGrayBlueColor }} />
        </Stack>
      </Box>

      <Divider sx={{ borderColor: variables.dataBackgroundColor }} />

      <Box mt={2} mx={3}>
        <Typography variant="h6" gutterBottom>
          <Skeleton animation="wave" width={100} sx={{ bgcolor: variables.lightGrayBlueColor }} />
        </Typography>

        <Stack spacing={1} mb={2}>
          <Skeleton animation="wave" variant="text" width={300} sx={{ bgcolor: variables.lightGrayBlueColor }} />
          <Skeleton animation="wave" variant="text" width={150} sx={{ bgcolor: variables.lightGrayBlueColor }} />
          <Skeleton animation="wave" variant="text" width={220} sx={{ bgcolor: variables.lightGrayBlueColor }} />
        </Stack>
      </Box>

      <Divider sx={{ my: 2, borderColor: variables.dataBackgroundColor }} />

      <Box mt={2} mx={3}>
        <Box mt={2}>
          <Typography variant="subtitle1" gutterBottom>
            <Skeleton animation="wave" width={100} sx={{ bgcolor: variables.lightGrayBlueColor }} />
          </Typography>
          <Skeleton animation="wave" variant='rectangular' width='100%' height={200} sx={{ bgcolor: variables.lightGrayBlueColor, borderRadius: '4px' }} />
        </Box>
      </Box>
    </>
  )
}
