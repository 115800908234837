import React from 'react'
import { Box } from '@mui/material'
import { EntryData } from '../EntryData'
import { formatSize } from '../../../EntryDetailed/EntryDetailed'

import styles from './Bandwidth.module.sass'

type BandwidthType = 'requestSize' | 'responseSize' | 'size'

export const RequestBandwidth: BandwidthType = 'requestSize'
export const ResponseBandwidth: BandwidthType = 'responseSize'
export const TotalBandwidth: BandwidthType = 'size'

interface BandwidthProps {
  type: BandwidthType,
  size: number
}

export const Bandwidth: React.FC<BandwidthProps> = ({ type, size }) => {
  return (
    <Box className={styles.Bandwidth}>
      <EntryData
        name={`${formatSize(size)}`}
        query={`${type} == "${size}"`}
      />
    </Box>
  )
}
