import React from 'react'
import variables from '../../../../variables.module.scss'
import { Box, Fade } from '@mui/material'

interface ConsoleBannerProps {
  title: string
  icon: React.ReactNode
  action?: React.ReactNode
}

export const ConsoleBanner: React.FC<ConsoleBannerProps> = ({
  title,
  icon,
  action
}) => {
  return (
    <Fade in>
      <Box
        boxSizing='border-box'
        height='100%'
        width='100%'
        display='flex'
        alignItems='center'
        flexDirection='column'
        gap='15px'
        justifyContent='center'
        borderRadius='4px'
        bgcolor={variables.githubEditorBackgroundColorLight}
      >
        {icon}
        <h1
          style={{
            fontFamily: 'Roboto',
            fontSize: '24px',
            fontWeight: 600,
            color: variables.lighterGrayColor
          }}
        >
          {title}
        </h1>
        {action}
      </Box>
    </Fade>
  )
}
