import './App.sass';
import React from "react";
import { TrafficPage } from "./components/Pages/TrafficPage/TrafficPage";
import { ThemeProvider, StyledEngineProvider, createTheme } from '@mui/material';

import * as UI from "./components/UI";
import useQueryRestore from './hooks/useQueryRestore';
import useInterceptor from './hooks/useInterceptor';
import { OidcAuthProvider } from './components/UI/Auth/OidcAuth/OidcAuthProvider';
import useLicenseUpdater from './hooks/useLicenseUpdater';
import useAuthTracker from './hooks/useAuthTracker';
import { useIntercomChat } from './hooks/useIntercomChat'
import { useIntercomEvents } from './hooks/useIntercomEvents'
import useSentryTracking from './hooks/useSentryTracking'
import useElementRouter from './hooks/useElementRouter'
import useScriptLogTracker from './hooks/useScriptLogTracker'

const App: React.FC = () => {
  useSentryTracking()
  useAuthTracker()
  useInterceptor()
  useLicenseUpdater()
  useIntercomChat()
  useIntercomEvents()
  useElementRouter()
  useQueryRestore()
  useScriptLogTracker()

  return (
    <StyledEngineProvider injectFirst>
      <OidcAuthProvider>
        <ThemeProvider theme={createTheme(({}))}>
          <UI.Auth />
          <div className="kubesharkApp">
            <TrafficPage />
          </div>
        </ThemeProvider>
      </OidcAuthProvider>
    </StyledEngineProvider>
  );
}

export default App;
