import React from "react";
import { EntryTableSection, EntryBodySection } from "../EntrySections/EntrySections";
import { SectionData } from "../../EntryListItem/Entry";

enum SectionTypes {
  SectionTable = "table",
  SectionBody = "body",
}

interface SectionsRepresentationProps {
  data: SectionData[];
  color: string;
}

const SectionsRepresentation: React.FC<SectionsRepresentationProps> = ({ data, color }) => {
  const sections = []

  if (data) {
    for (const [i, row] of data.entries()) {
      switch (row.type) {
      case SectionTypes.SectionTable:
        sections.push(
          <EntryTableSection key={i} title={row.title} color={color} arrayToIterate={row.tableData} />
        )
        break;
      case SectionTypes.SectionBody:
        sections.push(
          <EntryBodySection key={i} title={row.title} color={color} content={row.body} encoding={row.encoding} contentType={row.mimeType} selector={row.selector} />
        )
        break;
      default:
        break;
      }
    }
  }

  return <React.Fragment>{sections}</React.Fragment>;
}

export default SectionsRepresentation
