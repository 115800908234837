import React from 'react'

import style from './Sidebar.module.sass'

interface SidebarProps {
  isOpen: boolean
  children: React.ReactNode | React.ReactNode[]
}

const Sidebar: React.FC<SidebarProps> = ({ isOpen, children }) => {
  return (
    <div className={style.Sidebar} style={isOpen ? { left: '0px' } : null}>
      {children}
    </div>
  );
}

export default Sidebar
