import { atom } from "recoil";

type ViewMode = 'drawer' | 'slide'

const entryDetailsViewModeAtom = atom<ViewMode>({
  key: "entryDetailsViewModeAtom",
  default: 'slide'
});

export default entryDetailsViewModeAtom;
