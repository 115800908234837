import { atom } from "recoil";
import { EnabledDissectors } from '../../types/trafficSettings'
import { defaultEnabledDissectors } from './default'

const enabledDissectorsAtom = atom<EnabledDissectors>({
  key: "enabledDissectorsAtom",
  default: defaultEnabledDissectors,
});

export default enabledDissectorsAtom;
