import React from 'react'

import styles from './SidebarScript.module.sass'
import { Box, ButtonBase } from '@mui/material'
import variables from '../../../../variables.module.scss'
import CircleRoundedIcon from '@mui/icons-material/CircleRounded'

interface SidebarScriptProps {
  darkModeEnabled: boolean
  title: string
  active: boolean
  index: number
  selected: boolean
  onSelection: (index: number) => void
}

export const SidebarScript: React.FC<SidebarScriptProps> = ({ darkModeEnabled, title, active, index, selected, onSelection }) => {
  return (
    <ButtonBase
      component={Box}
      className={styles.SidebarScript}
      sx={{
        color: darkModeEnabled
          ? (selected ? variables.lightestGrayColor : variables.lighterGrayColor )
          : variables.grayColor,
        opacity: active ? 1 : 0.75,
        backgroundColor: selected ? (darkModeEnabled ? variables.slateColor : variables.lightGrayBlueColor) : null
      }}
      onClick={() => onSelection(index)}
    >
      <CircleRoundedIcon
        htmlColor={active ? variables.successColor : variables.lightGrayColor}
        sx={{ fontSize: '12px' }}
      />
      <p className={styles.SidebarScriptTitle}>
        {title}
      </p>
    </ButtonBase>
  )
}
