import React from 'react'

export const CollapsePanelIcon: React.FC = () => {
  return (
    <svg
      width='30'
      height='30'
      viewBox='0 0 30 30'
      fill='none'
      xmlns='http://www.w3.org/2000/svg'
    >
      <rect y='0.379883' width='30' height='30' rx='6' fill='#F7F9FC' />
      <path
        d='M9.65583 5.37988L6.93686 5.37988C6.53092 5.37988 6.1416 5.57743 5.85456 5.92906C5.56751 6.28069 5.40625 6.7576 5.40625 7.25488L5.40625 23.5049C5.40625 24.0022 5.56751 24.4791 5.85456 24.8307C6.1416 25.1823 6.53092 25.3799 6.93686 25.3799L10.4063 25.3799'
        stroke='#416CDE'
        strokeWidth='2'
        strokeMiterlimit='2.3662'
        strokeLinecap='round'
      />
      <path
        d='M24.5312 23.6152L24.5312 7.14459C24.5312 6.16997 23.616 5.37988 23.0312 5.37988L13.5901 5.37988C13.0053 5.37988 12.5312 6.16997 12.5312 7.14459L12.5312 23.6152C12.5312 24.5898 13.0053 25.3799 13.5901 25.3799L23.0312 25.3799C23.616 25.3799 24.5312 24.5898 24.5312 23.6152Z'
        fill='white'
        stroke='#BCCEFD'
        strokeWidth='2'
        strokeLinecap='round'
        strokeLinejoin='round'
        strokeDasharray='3 3'
      />
      <path
        d='M23.3081 18C23.3081 18.1994 23.4266 18.3798 23.6096 18.4589C23.7926 18.5381 24.0052 18.5009 24.1505 18.3643L27.3424 15.3643C27.443 15.2698 27.5 15.138 27.5 15C27.5 14.862 27.443 14.7302 27.3424 14.6357L24.1505 11.6357C24.0052 11.4991 23.7926 11.4619 23.6096 11.5411C23.4266 11.6202 23.3081 11.8006 23.3081 12L23.3081 13.5079L18 13.5079C17.8674 13.5079 17.7402 13.5606 17.6464 13.6544C17.5527 13.7482 17.5 13.8753 17.5 14.0079L17.5 15.9921C17.5 16.2682 17.7239 16.4921 18 16.4921L23.3081 16.4921L23.3081 18Z'
        fill='#416CDE'
        stroke='white'
        strokeLinejoin='round'
      />
    </svg>
  )
}
