import { HubBaseUrl } from '../../../consts'
import { toast } from 'react-toastify'

export interface TrafficCapturingSettingsResponse {
  stopped: boolean
  enabledDissectors: string[]
}

export const getCapturingStatus = () => {
  let status: number

  return fetch(
    `${HubBaseUrl}/settings/status`,
    {
      method: 'GET',
      headers: {
        'X-Kubeshark-Capture': 'ignore',
      },
    },
  )
    .then((response) => {
      status = response.status
      return response.ok
        ? response
        : response.text().then((err) => Promise.reject(err))
    })
    .then((response) => response.json())
    .catch((err) => {
      if (err.length > 0) {
        console.error(err)
        if (status > 400 && ![401, 502, 504].includes(status)) {
          toast.error(err.toString(), {
            theme: 'colored'
          })
        }
      }
    })
}
