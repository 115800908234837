import React, { useState, useEffect, useRef, useLayoutEffect, useCallback } from 'react'
import {
  IconButton,
  FormControl,
  InputLabel,
  Select,
  MenuItem,
  Card,
  CardContent,
  List,
  ListItem,
  ListItemIcon,
  ListItemText,
  FormControlLabel,
  DialogContentText,
  Checkbox,
  Box,
} from "@mui/material";
import { SelectChangeEvent } from '@mui/material/Select';
import RemoveIcon from '@mui/icons-material/Remove';
import AddIcon from '@mui/icons-material/Add';
import ForwardIcon from '@mui/icons-material/Forward';
import CircleIcon from '@mui/icons-material/Circle';
import { GraphData, Node, Edge, LegendData, ElementPosition } from "./ServiceMapModalTypes"
import ServiceMapOptions from './ServiceMapOptions'
import ForceGraph from "./ForceGraph";
import Moment from "moment";
import { useRecoilValue } from "recoil";

import seedrandom from "seedrandom";
import { Utils } from '../../../helpers/Utils'

import Queryable from "../../UI/Queryable/Queryable";
import NodeTypes from "./NodeTypes";
import EdgeTypes from "./EdgeTypes";
import {composeNodeFilter, EndpointType} from "./NodeFilters";
import composeEdgeFilter from "./EdgeFilters";

import entriesAtom from "../../../recoil/entries";
import { KubectlCard } from "./cards/KubectlCard";
import { FullscreenViewButton } from "../../UI/FullscreenView/FullscreenViewButton";
import fullscreenViewAtom from "../../../recoil/fullscreenView/atom";
import useStreamingWebSocket from '../../../hooks/useStreamingWebSocket'
import { useInterval } from '../../../helpers/interval'

/**
 * Converts a long string of bytes into a readable format e.g KB, MB, GB, TB, YB
 *
 * @param {Int} num The number of bytes.
 */
function humanReadableBytes(bytes): string {
  let i = Math.floor(Math.log(bytes) / Math.log(1024));
  if (i < 0) i = 0;
  const sizes = ['B', 'KB', 'MB', 'GB', 'TB', 'PB', 'EB', 'ZB', 'YB'];
  const result = (bytes / Math.pow(1024, i)).toFixed(2);
  return result + ' ' + sizes[i];
}

const colorPalette = [
  "#FDFFB6",
  "#9BF6FF",
  "#BDB2FF",
  "#FFFFFC",
  "#FFD6A5",
  "#CAFFBF",
  "#A0C4FF",
  "#FFC6FF",
  "#FFADAD",
]

export const ServiceMap: React.FC = () => {
  const [edgeType, setEdgeType] = useState("bandwidth");
  const [nodeType, setNodeType] = useState("name");

  const [graphData, setGraphData] = useState<GraphData>({ nodes: [], edges: [] });
  const [graphOptions, setGraphOptions] = useState(ServiceMapOptions);

  const [legendData, setLegendData] = useState<LegendData>({});

  const [selectedEdges, setSelectedEdges] = useState([]);
  const [selectedNodes, setSelectedNodes] = useState([]);

  const [selectingFromHighlight, setSelectingFromHighlight] = useState(false);
  const [hoveredElementPosition, setHoveredElementPosition] = useState<ElementPosition>({x: 0, y: 0})

  const [showTooltip, setShowTooltip] = useState(false);
  const [tooltipQuery, setTooltipQuery] = useState("");

  const [showCumulative, setShowCumulative] = React.useState(false);
  const [showRequests, setShowRequests] = React.useState(true);
  const [showResponses, setShowResponses] = React.useState(true);

  const [maximizeOptionsCard, setMaximizeOptionsCard] = React.useState(true);
  const [maximizeLegendCard, setMaximizeLegendCard] = React.useState(true);

  const entries = useRecoilValue(entriesAtom)
  const fullscreenView = useRecoilValue(fullscreenViewAtom)

  const { readyState } = useStreamingWebSocket()

  const [renderClock, setRenderClock] = useState(0)

  useInterval(useCallback(() => {
    if (readyState === WebSocket.OPEN) {
      setRenderClock(Date.now())
    }
  }, [readyState]), 1000, true)

  useEffect(() => {
    const nodeMap = {};
    const edgeMap = {};
    const nodes: Node[] = [];
    const edges: Edge[] = [];
    const legendMap = {};

    let firstMoment: Moment.Moment;

    [...entries.values()].map(entry => {
      const thisMoment = Moment(+entry.timestamp)?.utc();
      if (firstMoment === undefined) firstMoment = thisMoment;
      if (thisMoment.diff(firstMoment, "seconds") < 0) firstMoment = thisMoment;

      legendMap[entry.proto.abbr] = entry.proto;
      let srcLabel = entry.src.name;
      let dstLabel = entry.dst.name;
      let srcKey = `${entry.src.name}.${entry.src.namespace}`;
      let dstKey = `${entry.dst.name}.${entry.dst.namespace}`;

      let srcName = "";
      let dstName = "";
      let srcVerb = "";
      let dstVerb = "";

      switch (nodeType) {
      case NodeTypes.Name:
        if (entry.src.pod) {
          srcVerb = NodeTypes.Pod;
          srcName = entry.src.pod.metadata.name;
        } else if (entry.src.endpointSlice) {
          srcVerb = NodeTypes.EndpointSlice;
          srcName = entry.src.endpointSlice.metadata.name;
        } else if (entry.src.service) {
          srcVerb = NodeTypes.Service;
          srcName = entry.src.service.metadata.name;
        }
        break;
      case NodeTypes.Namespace:
        if (entry.src.pod) {
          srcLabel = entry.src.pod.metadata.namespace;
        } else if (entry.src.endpointSlice) {
          srcLabel = entry.src.endpointSlice.metadata.namespace;
        } else if (entry.src.service) {
          srcLabel = entry.src.service.metadata.namespace;
        }
        srcKey = srcLabel;

        srcVerb = NodeTypes.Namespace;
        srcName = srcLabel;
        break;
      case NodeTypes.Pod:
        if (entry.src.pod) {
          srcLabel = entry.src.pod.metadata.name;
          srcKey = `${entry.src.pod.metadata.name}.${entry.src.pod.metadata.namespace}`;
          srcName = entry.src.pod.metadata.name;
        }

        srcVerb = NodeTypes.Pod;
        break;
      case NodeTypes.EndpointSlice:
        if (entry.src.endpointSlice) {
          srcLabel = entry.src.endpointSlice.metadata.name;
          srcKey = `${entry.src.endpointSlice.metadata.name}.${entry.src.endpointSlice.metadata.namespace}`;
          srcName = entry.src.endpointSlice.metadata.name;
        }

        srcVerb = NodeTypes.EndpointSlice;
        break;
      case NodeTypes.Service:
        if (entry.src.service) {
          srcLabel = entry.src.service.metadata.name;
          srcKey = `${entry.src.service.metadata.name}.${entry.src.service.metadata.namespace}`;
          srcName = entry.src.service.metadata.name;
        }

        srcVerb = NodeTypes.Service;
        break;
      }

      switch (nodeType) {
      case NodeTypes.Name:
        if (entry.dst.pod) {
          dstVerb = NodeTypes.Pod;
          dstName = entry.dst.pod.metadata.name;
        } else if (entry.dst.endpointSlice) {
          dstVerb = NodeTypes.EndpointSlice;
          dstName = entry.dst.endpointSlice.metadata.name;
        } else if (entry.dst.service) {
          dstVerb = NodeTypes.Service;
          dstName = entry.dst.service.metadata.name;
        }
        break;
      case NodeTypes.Namespace:
        if (entry.dst.pod) {
          dstLabel = entry.dst.pod.metadata.namespace;
        } else if (entry.dst.endpointSlice) {
          dstLabel = entry.dst.endpointSlice.metadata.namespace;
        } else if (entry.dst.service) {
          dstLabel = entry.dst.service.metadata.namespace;
        }
        dstKey = dstLabel;

        dstVerb = NodeTypes.Namespace;
        dstName = dstLabel;
        break;
      case NodeTypes.Pod:
        if (entry.dst.pod) {
          dstLabel = entry.dst.pod.metadata.name;
          dstKey = `${entry.dst.pod.metadata.name}.${entry.dst.pod.metadata.namespace}`;
          dstName = entry.dst.pod.metadata.name;
        }

        dstVerb = NodeTypes.Pod;
        break;
      case NodeTypes.EndpointSlice:
        if (entry.dst.endpointSlice) {
          dstLabel = entry.dst.endpointSlice.metadata.name;
          dstKey = `${entry.dst.endpointSlice.metadata.name}.${entry.dst.endpointSlice.metadata.namespace}`;
          dstName = entry.dst.endpointSlice.metadata.name;
        }

        dstVerb = NodeTypes.EndpointSlice;
        break;
      case NodeTypes.Service:
        if (entry.dst.service) {
          dstLabel = entry.dst.service.metadata.name;
          dstKey = `${entry.dst.service.metadata.name}.${entry.dst.service.metadata.namespace}`;
          dstName = entry.dst.service.metadata.name;
        }

        dstVerb = NodeTypes.Service;
        break;
      }

      switch (nodeType) {
      case NodeTypes.WorkerNode:
        if (entry.src.pod && entry.dst.pod) {
          srcLabel = entry.src.pod.spec.nodeName
          srcKey = entry.src.pod.spec.nodeName
          srcName = entry.src.pod.spec.nodeName

          dstLabel = entry.dst.pod.spec.nodeName
          dstKey = entry.dst.pod.spec.nodeName
          dstName = entry.dst.pod.spec.nodeName
        } else {
          srcKey = ""
          dstKey = ""
        }

        srcVerb = NodeTypes.WorkerNode
        dstVerb = NodeTypes.WorkerNode

        break;
      }

      if (srcLabel.length === 0) {
        srcLabel = entry.src.ip;
      }
      if (dstLabel.length === 0) {
        dstLabel = entry.dst.ip;
      }

      let srcId: number;
      let dstId: number;

      const keyArr: string[] = [srcKey, dstKey].filter((keyElem) => keyElem.length > 0);
      const labelArr: string[] = [srcLabel, dstLabel];
      const nameArr: string[] = [srcName, dstName];
      const namespaceArr: string[] = [entry.src.namespace, entry.dst.namespace];
      const verbArr: string[] = [srcVerb, dstVerb];
      for (let i = 0; i < keyArr.length; i++) {
        const nodeKey: string = keyArr[i];
        let node: Node;
        const namespace = namespaceArr[i];
        if (nodeKey in nodeMap) {
          node = nodeMap[nodeKey]
          nodeMap[nodeKey].value++;
        } else {
          let endpointType: EndpointType = null

          if (i === 0) {
            endpointType = EndpointType.Source
          } else if (i === 1) {
            endpointType = EndpointType.Destination
          }

          node = {
            id: nodes.length,
            value: 1,
            label: labelArr[i],
            filter: composeNodeFilter(nodeType, entry, endpointType),
            group: namespace,
            title: nodeKey,
            name: nameArr[i],
            namespace: namespace,
            verb: verbArr[i],
          };
          nodeMap[nodeKey] = node;
          nodes.push(node);

          if (!(namespace in ServiceMapOptions.groups)) {
            const rng = seedrandom(namespace);
            let n = rng.int32();
            if (n < 0) n = -n;
            ServiceMapOptions.groups[namespace] = {
              key: namespace,
              color: colorPalette[n % 9],
              filter: `src.namespace == "${namespace}" or dst.namespace == "${namespace}"`,
            }
          }
        }

        if (i == 0)
          srcId = node.id;
        else
          dstId = node.id;
      }

      const edgeKey = `${entry.proto.abbr}_${srcId}_${dstId}`;

      let edge: Edge;
      if (edgeKey in edgeMap) {
        edge = edgeMap[edgeKey];
        if (edge.dashes === false) edge.dashes = entry.error !== null;
      } else {
        edge = {
          id: edges.length,
          from: srcId,
          to: dstId,
          value: 1,
          count: 1,
          cumulative: 1,
          label: "",
          filter: composeEdgeFilter(nodeType, entry),
          proto: entry.proto.abbr,
          title: entry.proto.longName,
          color: entry.proto.backgroundColor,
          dashes: entry.error !== null,
        }
        edgeMap[edgeKey] = edge;
        edges.push(edge);
      }

      const secondsPassed = thisMoment.diff(firstMoment, "seconds");

      switch (edgeType) {
      case EdgeTypes.Bandwidth:
        if (showRequests)
          edgeMap[edgeKey].cumulative += entry.requestSize;
        if (showResponses)
          edgeMap[edgeKey].cumulative += entry.responseSize;

        if (showCumulative)
          edgeMap[edgeKey].value = edgeMap[edgeKey].cumulative;
        else
          edgeMap[edgeKey].value = edgeMap[edgeKey].cumulative / secondsPassed;

        if (!showCumulative)
          edgeMap[edgeKey].label += "/s";

        edgeMap[edgeKey].label = Utils.isReadableNumber(edgeMap[edgeKey].value) ?
          humanReadableBytes(edgeMap[edgeKey].value) : ''

        break;
      case EdgeTypes.Throughput:
        edgeMap[edgeKey].cumulative++;

        if (showCumulative)
          edgeMap[edgeKey].value = edgeMap[edgeKey].cumulative;
        else
          edgeMap[edgeKey].value = Math.round(edgeMap[edgeKey].cumulative / secondsPassed * 100) / 100;

        if (!showCumulative)
          edgeMap[edgeKey].label += "/s";

        edgeMap[edgeKey].label = Utils.isReadableNumber(edgeMap[edgeKey].value) ?
          `${edgeMap[edgeKey].value}` : '';

        break;
      case EdgeTypes.Latency:
        edgeMap[edgeKey].value = Math.round((entry.elapsedTime + edgeMap[edgeKey].value * edgeMap[edgeKey].count) / (edgeMap[edgeKey].count + 1) * 100) / 100;
        edgeMap[edgeKey].label = Utils.isReadableNumber(edgeMap[edgeKey].value) ?
          `${edgeMap[edgeKey].value} μs` : '';
        break;
      }

      edgeMap[edgeKey].count++;
    });

    setGraphData({
      nodes: nodes,
      edges: edges,
    });

    const legendMapSorted = Object.keys(legendMap).sort().reduce(
      (obj, key) => {
        obj[key] = legendMap[key];
        return obj;
      },
      {}
    );

    setLegendData(legendMapSorted);
  }, [renderClock, edgeType, nodeType]);

  useEffect(() => {
    if (graphData?.nodes?.length === 0) return;
    const options = { ...graphOptions };
    setGraphOptions(options);
  }, [graphData?.nodes?.length]);

  const handleEdgeChange = (event: SelectChangeEvent) => {
    setSelectedEdges([]);
    setGraphData({
      nodes: [],
      edges: [],
    });
    setEdgeType(event.target.value as string);
  };

  const handleNodeChange = (event: SelectChangeEvent) => {
    setSelectedNodes([]);
    setGraphData({
      nodes: [],
      edges: [],
    });
    setNodeType(event.target.value as string);
  };

  const events = {
    select: ({nodes, edges}) => {
      setSelectedEdges(edges);
      setSelectedNodes(nodes);
    },
    hoverNode: ({node: nodeId, event}) => {
      if (selectingFromHighlight) {
        return
      }

      setTooltipQuery(graphData.nodes[nodeId].filter)
      setHoveredElementPosition({x: event.offsetX, y: event.offsetY})
      setShowTooltip(true)
    },
    blurNode: () => {
      if (selectingFromHighlight) {
        return
      }

      setShowTooltip(false)
      setTooltipQuery("")
      setHoveredElementPosition({x: 0, y: 0})
    },
    hoverEdge: ({edge: edgeId, event}) => {
      if (selectingFromHighlight) {
        return
      }

      setTooltipQuery(graphData.edges[edgeId].filter)
      setHoveredElementPosition({x: event.offsetX, y: event.offsetY})
      setShowTooltip(true)
    },
    blurEdge: () => {
      if (selectingFromHighlight) {
        return
      }

      setShowTooltip(false)
      setTooltipQuery("")
      setHoveredElementPosition({x: 0, y: 0})
    }
  }

  const handleShowCumulativeCheck = (event: React.ChangeEvent<HTMLInputElement>) => {
    setShowCumulative(event.target.checked);
  };

  const handleShowRequestsCheck = (event: React.ChangeEvent<HTMLInputElement>) => {
    setShowRequests(event.target.checked);
  };

  const handleShowResponsesCheck = (event: React.ChangeEvent<HTMLInputElement>) => {
    setShowResponses(event.target.checked);
  };

  const mapContainerRef = useRef(null);

  const [graphHeight, setGraphHeight] = React.useState(650)

  let graphNetworkApi = null

  function setGraphNetworkApi(networkApi) {
    graphNetworkApi = networkApi
  }

  const handleResize = () => {
    setGraphHeight(mapContainerRef.current.clientHeight)
    if (graphNetworkApi) {
      graphNetworkApi.fit()
    }
  };

  useLayoutEffect(() => {
    handleResize()

    window.addEventListener('resize', handleResize);

    return () => {
      window.removeEventListener('resize', handleResize);
    };
  }, []);

  useEffect(() => {
    setGraphHeight(mapContainerRef.current.clientHeight)
    if (graphNetworkApi) {
      graphNetworkApi.fit()
    }
  }, [fullscreenView])

  return (
    <div ref={mapContainerRef} style={{
      position: "relative",
      width: "100%",
      height: "40vh",
      flexGrow: 1,
      backgroundColor: '#F0F5FF',
      color: '#000',
      overflow: 'hidden'
    }}>
      <Box position='absolute' top='5px' right='5px' zIndex={1}>
        <FullscreenViewButton />
      </Box>
      <Card sx={{
        maxWidth: "20%",
        position: "absolute",
        left: "0.5%",
        zIndex: 1,
      }}>
        {maximizeOptionsCard ?
          <IconButton onClick={() => {
            setMaximizeOptionsCard(false);
          }} style={{
            margin: "2px",
            float: "right",
            padding: "2px",
          }}>
            <RemoveIcon />
          </IconButton>
          :
          <IconButton onClick={() => {
            setMaximizeOptionsCard(true);
          }} style={{
            margin: "2px",
            float: "right",
            padding: "2px",
          }}>
            <AddIcon />
          </IconButton>
        }

        {maximizeOptionsCard && <CardContent>
          <FormControl fullWidth size="small">
            <InputLabel id="edge-select-label">Edges</InputLabel>
            <Select
              labelId="edge-select-label"
              id="edge-select"
              value={edgeType}
              label="Edge"
              onChange={handleEdgeChange}
            >
              <MenuItem value={EdgeTypes.Bandwidth}>Bandwidth</MenuItem>
              <MenuItem value={EdgeTypes.Throughput}>Throughput</MenuItem>
              <MenuItem value={EdgeTypes.Latency}>Latency</MenuItem>
            </Select>
          </FormControl>

          <FormControl fullWidth size="small" sx={{marginTop: "20px"}}>
            <InputLabel id="node-select-label">Nodes</InputLabel>
            <Select
              labelId="node-select-label"
              id="node-select"
              value={nodeType}
              label="Node"
              onChange={handleNodeChange}
            >
              <MenuItem value={NodeTypes.Name}>Resolved Name</MenuItem>
              <MenuItem value={NodeTypes.Namespace}>Namespace</MenuItem>
              <MenuItem value={NodeTypes.Pod}>Pod</MenuItem>
              <MenuItem value={NodeTypes.EndpointSlice}>EndpointSlice</MenuItem>
              <MenuItem value={NodeTypes.Service}>Service</MenuItem>
              <MenuItem value={NodeTypes.WorkerNode}>Node</MenuItem>
            </Select>
          </FormControl>

          {(edgeType === EdgeTypes.Bandwidth || edgeType === EdgeTypes.Throughput) && <FormControlLabel
            label={<DialogContentText style={{marginTop: "4px"}}>Show cumulative {edgeType === EdgeTypes.Bandwidth ? EdgeTypes.Bandwidth : ""}{edgeType === EdgeTypes.Throughput ? EdgeTypes.Throughput : ""}</DialogContentText>}
            control={<Checkbox checked={showCumulative} onChange={handleShowCumulativeCheck} />}
            style={{marginTop: "5px"}}
            labelPlacement="end"
          />}

          {edgeType === EdgeTypes.Bandwidth && <FormControlLabel
            label={<DialogContentText style={{marginTop: "4px"}}>Include request sizes</DialogContentText>}
            control={<Checkbox checked={showRequests} onChange={handleShowRequestsCheck} />}
            labelPlacement="end"
          />}

          {edgeType === EdgeTypes.Bandwidth && <FormControlLabel
            label={<DialogContentText style={{marginTop: "4px"}}>Include response sizes</DialogContentText>}
            control={<Checkbox checked={showResponses} onChange={handleShowResponsesCheck} />}
            labelPlacement="end"
          />}
        </CardContent>}
      </Card>

      {Object.keys(legendData).length > 0 && <Card sx={{
        maxWidth: "30%",
        position: "absolute",
        left: "0.5%",
        bottom: "1%",
        zIndex: 1,
      }}>
        {maximizeLegendCard ?
          <IconButton onClick={() => {
            setMaximizeLegendCard(false);
          }} style={{
            margin: "2px",
            float: "right",
            padding: "2px",
          }}>
            <RemoveIcon />
          </IconButton>
          :
          <IconButton onClick={() => {
            setMaximizeLegendCard(true);
          }} style={{
            margin: "2px",
            float: "right",
            padding: "2px",
          }}>
            <AddIcon />
          </IconButton>
        }

        {maximizeLegendCard && <CardContent sx={{ maxHeight: "22vh", overflow: "scroll" }}>
          <List dense disablePadding>
            {
              Object.keys(legendData).map(function(key) {
                const proto = legendData[key];
                const primaryStyle = {
                  color: proto.backgroundColor,
                  fontWeight: "bold",
                };
                const secondaryStyle = {
                  color: proto.backgroundColor,
                };

                return <ListItem key={key} disableGutters disablePadding sx={{
                  backgroundColor: "#E8E8E8",
                  padding: "0 5px 0 5px",
                  marginBottom: "5px",
                  border: "2px solid white",
                  "&:hover": {
                    border: `2px solid ${proto.backgroundColor}`
                  },
                  cursor: "pointer",
                }}
                onClick={() => {
                  const edges = [];
                  graphData.edges.forEach(edge => {
                    if (edge.proto === proto.abbr) {
                      edges.push(edge.id);
                    }
                  });
                  setSelectedNodes([]);
                  setSelectedEdges(edges);
                }}>
                  <ListItemIcon sx={{ minWidth: "36px" }}>
                    <ForwardIcon sx={{ color: proto.backgroundColor }} />
                  </ListItemIcon>
                  <ListItemText
                    primary={proto.abbr}
                    secondary={proto.longName}
                    primaryTypographyProps={{ style: primaryStyle }}
                    secondaryTypographyProps={{ style: secondaryStyle }}
                  />
                </ListItem>
              })
            }
            {
              Object.keys(ServiceMapOptions.groups).map(function(key) {
                if (!key) return;

                const group = ServiceMapOptions.groups[key];
                const primaryStyle = {
                  color: group.color,
                  fontWeight: "bold",
                };
                const secondaryStyle = {
                  color: group.color,
                };

                return <ListItem key={key} disableGutters disablePadding sx={{
                  backgroundColor: "#505050",
                  padding: "0 5px 0 5px",
                  marginBottom: "5px",
                  border: "2px solid white",
                  "&:hover": {
                    border: `2px solid ${group.color}`
                  },
                  cursor: "pointer",
                }}
                onClick={() => {
                  const nodes = [];
                  graphData.nodes.forEach(node => {
                    if (node.group === group.key) {
                      nodes.push(node.id);
                    }
                  });
                  setSelectedEdges([]);
                  setSelectedNodes(nodes);
                }}>
                  <ListItemIcon sx={{ minWidth: "36px" }}>
                    <CircleIcon sx={{ color: group.color }} />
                  </ListItemIcon>
                  <ListItemText
                    primary={key}
                    secondary="Namespace"
                    primaryTypographyProps={{ style: primaryStyle }}
                    secondaryTypographyProps={{ style: secondaryStyle }}
                  />
                </ListItem>
              })
            }
          </List>
        </CardContent>}
      </Card>}

      {false && <KubectlCard
        selectedNodes={selectedNodes}
        graphData={graphData}
      />}

      {graphData.nodes.length > 0 && graphData.edges.length && <ForceGraph
        getNetwork={setGraphNetworkApi}
        graph={graphData}
        options={{...graphOptions, height: `${graphHeight}px` }}
        events={events}
        modalRef={mapContainerRef}
        setSelectedNodes={setSelectedNodes}
        setSelectedEdges={setSelectedEdges}
        enableSelectingFromHighlight={false}
        setSelectingFromHighlight={setSelectingFromHighlight}
        selection={{
          nodes: selectedNodes,
          edges: selectedEdges,
        }}/>
      }
      {showTooltip && <Queryable
        query={tooltipQuery}
        joinCondition="or"
        displayImmediately={showTooltip}
        onAdded={() => setShowTooltip(false)}
        flipped={true}
        tooltipStyle={{ top: 0, marginTop: "-5px", marginLeft: "25px" }}
        style={{
          position: "absolute",
          left: `${hoveredElementPosition.x}px`,
          top: `${hoveredElementPosition.y}px`,
          whiteSpace: "nowrap",
          zIndex: 1400
        }}
      >
      </Queryable>}
    </div>
  );
}
