import React, { useEffect, useState } from 'react'

import { Box, DialogContent, IconButton, Link, Modal, Typography } from '@mui/material'

import { useRecoilState, useRecoilValue } from 'recoil'
import trafficSettingsModalOpenAtom from '../../../recoil/trafficSettingsModalOpen'

import variables from '../../../variables.module.scss'
import styles from './TrafficSettingsModal.module.sass'

import CloseRoundedIcon from '@mui/icons-material/CloseRounded'
import packetCapturingStoppedAtom from '../../../recoil/packetCapturingStopped'
import { appStopTrafficCapturingDisabled } from '../../../types/global'
import useElementRouter from '../../../hooks/useElementRouter'
import { RootView } from './views/RootView/RootView'
import SettingsRoundedIcon from '@mui/icons-material/SettingsRounded'
import { LinkHelmChartReadme } from '../../../consts'
import { InlineCode } from '../../UI/InlineCode/InlineCode'

export const TrafficSettingsModal: React.FC = () => {
  const [modalOpen, setModalOpen] = useRecoilState(trafficSettingsModalOpenAtom)
  const [resolvedOpenState, setResolvedOpenState] = useState(false)

  useEffect(() => {
    if (typeof modalOpen === 'boolean') {
      setResolvedOpenState(modalOpen)
    } else if (typeof modalOpen === 'object') {
      setResolvedOpenState(modalOpen.open)
    }
  }, [modalOpen])

  const capturingStopped = useRecoilValue(packetCapturingStoppedAtom)

  const { navigateToElement } = useElementRouter()

  const handleCloseModal = () => {
    if (!appStopTrafficCapturingDisabled() && capturingStopped) {
      return
    }

    setModalOpen(false)
    navigateToElement('')
  }

  return (
    <Modal open={resolvedOpenState} onClose={handleCloseModal} disableAutoFocus>
      <DialogContent>
        <Box className={styles.TrafficSettingsModal}>
          <Box display='flex' flexDirection='column' gap='20px' width='100%'>
            <Box
              display='flex'
              alignItems='center'
              justifyContent='space-between'
              width='100%'
            >
              <Box display='flex' alignItems='center' gap='8px'>
                <SettingsRoundedIcon
                  htmlColor={variables.blueColor}
                  sx={{ fontSize: '28px' }}
                />
                <Typography
                  variant='h1'
                  fontWeight='700'
                  fontSize='32px'
                  fontFamily={variables.textFontFamily}
                  textTransform='none'
                  color={variables.fontColor}
                >
                  Settings
                </Typography>
              </Box>
              <IconButton
                onClick={handleCloseModal}
                disabled={
                  !appStopTrafficCapturingDisabled() && capturingStopped
                }
              >
                <CloseRoundedIcon
                  htmlColor={
                    !appStopTrafficCapturingDisabled() && capturingStopped
                      ? variables.lighterGrayColor
                      : variables.grayColor
                  }
                />
              </IconButton>
            </Box>
            <Typography
              variant='body1'
              fontWeight='500'
              fontSize='15px'
              fontFamily={variables.textFontFamily}
              textTransform='none'
              color={variables.grayFontColor}
            >
              This dialog window displays the values available in the&nbsp;
              <InlineCode color={variables.fontColor}>
                kubeshark-config-map
              </InlineCode>.
              Changes will remain in effect until Kubeshark is reinstalled.
              For permanent changes, modify the Helm values.
              Learn more&nbsp;
              <Link
                href={LinkHelmChartReadme}
                target='_blank'
                fontWeight='700'
                color={variables.blueColor}
              >
                here
              </Link>.
            </Typography>
          </Box>
          <RootView />
        </Box>
      </DialogContent>
    </Modal>
  )
}
