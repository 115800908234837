import React from 'react'
import variables from '../../variables.module.scss'
import { Box, CircularProgress } from '@mui/material'

export const LoadingBadge = () => {
  return (
    <Box
      boxSizing='border-box'
      position='absolute'
      top='110px'
      left='50%'
      display='flex'
      alignItems='center'
      gap='8px'
      padding='6px 10px'
      borderRadius='4px'
      bgcolor={variables.mainBackgroundColor}
      boxShadow={variables.lightSpreadShadowBottom}
      zIndex={100}
      sx={{
        border: `1px solid ${variables.lighterGrayColor}`,
        transform: 'translateX(-50%)'
      }}
    >
      <CircularProgress size={12} />
      <span
        style={{
          fontSize: '12px',
          fontWeight: 600,
          color: variables.fontColor,
        }}
      >
        Loading item details
      </span>
    </Box>
  )
}
