import React from 'react'
import { Button, Link, Tooltip, Typography } from '@mui/material'

import useLicense from '../../../hooks/useLicense'

import UpgradeIcon from '@mui/icons-material/Upgrade'
import { LinkPricingPage } from '../../../consts'
import variables from '../../../variables.module.scss'

interface UpgradeLicenseButtonProps {
  size: 'small' | 'large' | 'medium'
  fullWidth?: boolean
  icon?: boolean
  disabled?: boolean
}

export const UpgradeLicenseButton: React.FC<UpgradeLicenseButtonProps> = ({ size, fullWidth, icon, disabled = false }) => {
  const { valid: licenseValid } = useLicense()

  return (
    <Tooltip
      placement='top'
      title='You will be forwarded to Kubeshark pricing page.'
      arrow
    >
      <Link href={LinkPricingPage} target='_blank' rel='noopener noreferrer'>
        <Button
          fullWidth={fullWidth}
          variant='contained'
          size={size}
          startIcon={icon && <UpgradeIcon sx={{ marginRight: '5px' }} />}
          disabled={disabled}
          sx={{ backgroundColor: licenseValid ? variables.blueColor : variables.upgradeColor }}
        >
          <Typography
            variant='body1'
            fontFamily={variables.textFontFamily}
            fontWeight={600}
            color='#FFFFFF'
          >
            Upgrade
          </Typography>
        </Button>
      </Link>
    </Tooltip>
  )
}
