import React from 'react'
import CircleIcon from '@mui/icons-material/Circle'

import variables from '../../../variables.module.scss'

interface CircleDividerProps {
  ml?: number | string
  mr?: number | string
  bgColor?: string
  opacity?: number
}

export const CircleDivider: React.FC<CircleDividerProps> = ({ ml = 0, mr = 0, bgColor = variables.lightBlueColor, opacity = 1 }) => {
  return (
    <CircleIcon htmlColor={bgColor} sx={{ fontSize: '8px', ml: ml, mr: mr, opacity: opacity}} />
  )
}
