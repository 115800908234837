import { extractJwtPayload } from './SamlAuth'

interface AttributesPayload {
  email?: string[]
}

export const getSamlAttributesPayload = (): Array<AttributesPayload> => {
  const jwtPayload = extractJwtPayload() ?? []
  return jwtPayload['attr'] as Array<AttributesPayload>
}
