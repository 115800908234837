import React, { useEffect, useState } from 'react'
import { Entry } from '../../../EntryListItem/Entry'
import Queryable from '../../../UI/Queryable/Queryable'

import styles from './DuplicateBadge.module.sass'
import { Box } from '@mui/material'

interface DuplicateBadgeProps {
  duplicate: boolean
  duplicateOrigin: Entry
}

export const DuplicateBadge: React.FC<DuplicateBadgeProps> = ({ duplicate, duplicateOrigin }) => {
  if (!duplicate) {
    return <></>
  }

  const [originQuery, setOriginQuery] = useState('')

  useEffect(() => {
    if (duplicateOrigin === null) {
      return
    }

    const ipWithoutPort = duplicateOrigin?.worker.replace(/:\d+/, '')
    setOriginQuery(`node.ip == "${ipWithoutPort}" and stream == "${duplicateOrigin?.stream}"`)
  }, [])

  return (
    <Box className={styles.DuplicateBadge}>
      <Queryable
        query={originQuery}
        displayIconOnMouseOver={true}
        flipped={true}
        style={{ whiteSpace: 'nowrap', overflow: 'visible' }}
        iconStyle={{
          transform: 'translate(-5px, -10px)',
        }}
        tooltipStyle={{
          transform: 'translate(15px, -20px)',
        }}
      >
        <span className={styles.DuplicateBadgeText}>
          dup
        </span>
      </Queryable>
    </Box>
  )
}
