import React from 'react'
import { Box } from '@mui/material'
import variables from '../../../../variables.module.scss'

interface SidebarBannerProps {
  title: string
  icon: React.ReactNode
  darkModeEnabled: boolean
  action?: React.ReactNode
}

export const SidebarBanner: React.FC<SidebarBannerProps> = ({ title, icon, darkModeEnabled, action }) => {
  return (
    <Box
      boxSizing='border-box'
      height='80%'
      width='100%'
      display='flex'
      alignItems='center'
      flexDirection='column'
      gap='15px'
      justifyContent='center'
    >
      {icon}
      <h1
        style={{
          fontFamily: 'Roboto',
          fontSize: '16px',
          fontWeight: 500,
          color: darkModeEnabled ?
            variables.lighterGrayColor : variables.slateColor
        }}
      >
        {title}
      </h1>
      {action}
    </Box>
  )
}
