import React from 'react'
import {
  appAuthEnabled,
  appOidcAuthTypeEnabled
} from '../../../../types/global'
import { AuthProvider } from '@descope/react-sdk'

interface OidcAuthProviderProps {
  children: React.ReactElement
}

const isOidcAuthReady = (): boolean => {
  return appAuthEnabled() && appOidcAuthTypeEnabled()
}

export const OidcAuthProvider: React.FC<OidcAuthProviderProps> = ({
  children
}) => {
  if (isOidcAuthReady()) {
    return <AuthProvider projectId='P2PyftOipp2EcXXRyivNwlJkUMHm'>{children}</AuthProvider>
  }
  return children
}
