import React from 'react'

export const StreamingPauseIcon = () => {
  return (
    <svg width="30" height="30" viewBox="0 0 30 30" fill="none" xmlns="http://www.w3.org/2000/svg">
      <rect width="30" height="30" rx="15" fill="#F7B202" />
      <rect x="11" y="9" width="2" height="11" rx="1" fill="white" />
      <rect x="17" y="9" width="2" height="11" rx="1" fill="white" />
    </svg>
  )
}
