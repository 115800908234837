import { LinearProgress, linearProgressClasses, styled } from '@mui/material';
import { Utils } from '../../../helpers/Utils';

import variables from '../../../variables.module.scss';

const PercentageProgress = styled(LinearProgress)(({ value }) => ({
  height: '100%',
  borderRadius: 4,
  [`&.${linearProgressClasses.colorPrimary}`]: {
    backgroundColor: variables.mainBackgroundColor,
    border: `1px solid ${Utils.getColorByPercentage(value)}`
  },
  [`& .${linearProgressClasses.bar}`]: {
    height: '100%',
    borderRadius: 4,
    borderTopRightRadius: 0,
    borderBottomRightRadius: 0,
    backgroundColor: Utils.getColorByPercentage(value)
  }
}));

export default PercentageProgress;
