import { atom } from "recoil";

export const trafficViewTabs = {
  API_CALLS: 0,
  SERVICE_MAP: 1,
  SCRIPTING: 2,
  CONSOLE: 3
}

export const trafficViewTabAtom = atom({
  key: 'trafficViewTabAtom',
  default: trafficViewTabs.API_CALLS
});

