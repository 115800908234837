import React from 'react'

import styles from './AllRecordingsView.module.sass'
import variables from '../../../variables.module.scss'
import { DialogActions, Button, DialogContent, Box, Typography, Chip } from '@mui/material';
import { Table } from '../../UI/Table/Table';

import { useSetRecoilState } from 'recoil';
import createRecordingDialogOpenAtom from "../../../recoil/createRecordingDialogOpen";
import { toast } from 'react-toastify';
import { HubBaseUrl } from '../../../consts';

import { RawRecording, Recording, Node, getBodyCells, headCells } from './AllRecordingsTableOptions';

import InfoRoundedIcon from '@mui/icons-material/InfoRounded';
import RadioButtonChecked from '@mui/icons-material/RadioButtonChecked';
import TipsAndUpdatesRoundedIcon from '@mui/icons-material/TipsAndUpdatesRounded';

import pluralize from 'pluralize';

interface AllRecordingsViewProps {
  switchToStartRecordingJob: () => void
}

export const AllRecordingsView: React.FC<AllRecordingsViewProps> = ({ switchToStartRecordingJob }) => {
  const [recordings, setRecordings] = React.useState<Recording[]>([])
  const [dataFetched, setDataFetched] = React.useState(false)

  React.useEffect(() => fetchRecordings(), [])

  const fetchRecordings = () => {
    let responseStatus: number;

    fetch(
      `${HubBaseUrl}/records`,
      {
        headers: {
          'Content-Type': 'application/json',
          'X-Kubeshark-Capture': 'ignore',
        },
      }
    )
      .then((response) =>
        response.ok
          ? response
          : response.text().then((err) => Promise.reject(err))
      )
      .then(response => {
        responseStatus = response.status;
        return response.json();
      })
      .then(data => {
        if (responseStatus === 200) {
          const recordings = prepareRecordings(data.records)
          setRecordings(recordings);
          setDataFetched(true)
        } else {
          toast.error(`Failed to get recording jobs!`, {
            theme: 'colored'
          });
        }
      })
      .catch((err) => {
        setDataFetched(true)

        console.error(err);
        toast.error(err.toString(), {
          theme: 'colored'
        });
      });
  }

  const bulkDeleteRecordings = (deletedRecordings: Recording[]) => {
    const records = deletedRecordings.map((rec: Recording) => {
      return rec.nodes.map((node: Node) => ({name: rec.name, worker: node.worker}))
    }).flat()

    fetch(
      `${HubBaseUrl}/records/bulk`,
      {
        method: 'DELETE',
        headers: {
          'Content-Type': 'application/json',
          'X-Kubeshark-Capture': 'ignore',
        },
        body: JSON.stringify({records})
      }
    )
      .then((response) =>
        response.ok
          ? response
          : response.text().then((err) => Promise.reject(err))
      )
      .then((response) => {
        if (response.status === 200) {
          toast.success(`${deletedRecordings.length} ${pluralize('recording', deletedRecordings.length)} deleted.`, {
            theme: 'colored',
          });

          const updatedRecordings = recordings.filter((rec: Recording) => {
            return !deletedRecordings.some((delRec: Recording) => JSON.stringify(rec) === JSON.stringify(delRec));
          })

          setRecordings(updatedRecordings)
        } else {
          toast.error(`Failed to delete selected recordings!`, {
            theme: 'colored'
          });
        }
      })
      .catch((err) => {
        console.error('Failed to delete selected recordings due to error:', err);
        toast.error(`Failed to delete selected ${pluralize('recording', deletedRecordings.length)}! See console for an error.`, {
          theme: 'colored'
        });
      });
  }

  const confirmDeletedEntries = React.useCallback(async (deletedRecordings: Recording[]) => {
    bulkDeleteRecordings(deletedRecordings)
  }, [recordings])

  const setDialogOpen = useSetRecoilState(createRecordingDialogOpenAtom)

  const handleClose = () => {
    setDialogOpen(false);
  }

  const prepareRecordings = (rawRecordings: RawRecording[]): Recording[] => {
    if (rawRecordings === null) {
      return []
    }

    const groupedRecordings = new Map<string, Recording>()

    rawRecordings.forEach((rec: RawRecording) => {
      if (groupedRecordings.has(rec.name)) {
        const updatedRec = groupedRecordings.get(rec.name)
        updatedRec.size += rec.size
        updatedRec.nodes.push({name: rec.node, worker: rec.worker})

        groupedRecordings.set(rec.name, updatedRec)
        return
      }

      groupedRecordings.set(rec.name, {
        name: rec.name,
        size: rec.size,
        nodes: [
          {name: rec.node, worker: rec.worker}
        ]
      })
    })

    return Array.from(groupedRecordings.values())
  }

  const getHintLabel = () => (
    <span style={{ fontFamily: variables.textFontFamily, color: variables.fontColor }}>
      <span style={{ fontWeight: 600 }}>Hint:</span> new recordings take time to get listed
    </span>
  )

  const bodyCells = getBodyCells()

  return (
    <>
      <DialogContent sx={{ overflow: 'auto', height: "430px" }}>
        {dataFetched && recordings.length === 0 && <Box className={styles.NoRecordingsNotice}>
          <InfoRoundedIcon color='disabled' sx={{ fontSize: "84px" }} />
          <Typography variant='body1' align='center' fontFamily={variables.textFontFamily} color={variables.secondaryFontColor}>
            No traffic recordings so far.
          </Typography>
          <Button
            title='Create a new recording'
            variant="contained"
            startIcon={<RadioButtonChecked />}
            sx={{ mt: "25px", backgroundColor: variables.blueColor }}
            onClick={switchToStartRecordingJob}
          >
            <Typography fontFamily={variables.textFontFamily} fontWeight={600} sx={{ textTransform: 'none', borderRadius: "4px" }}>
              Start a recording job
            </Typography>
          </Button>
        </Box>}
        {<Table
          rows={recordings}
          confirmDeletedEntries={confirmDeletedEntries}
          dataFetched={dataFetched}
          headCells={headCells}
          bodyCells={bodyCells}
          selectBy='name'
          searchBy='name'
        />}
      </DialogContent>
      <DialogActions sx={{ padding: '24px', justifyContent: 'space-between' }}>
        <Chip
          icon={<TipsAndUpdatesRoundedIcon />}
          variant='filled'
          label={getHintLabel()}
        />
        <Button
          variant='outlined'
          size='medium'
          style={{
            borderColor: variables.blueColor,
            color: variables.blueColor
          }}
          onClick={handleClose}
        >
          Close
        </Button>
      </DialogActions>
    </>
  )
}
