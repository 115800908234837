import { HubBaseUrl } from '../../../../consts'
import { toast } from 'react-toastify'

export const getScripts = () => {
  return fetch(
    `${HubBaseUrl}/scripts`,
    {
      method: 'GET',
      headers: {
        'X-Kubeshark-Capture': 'ignore',
      },
    },
  )
    .then((response) => {
      if (response.status === 425) {
        setTimeout(() => {
          getScripts()
        }, 1000)
      }

      return response;
    })
    .then(response => response.ok || response.status === 425 ? response : response.text().then(err => Promise.reject(err)))
    .then(response => response.json())
    .catch(err => {
      if (err.length > 0) {
        console.error(err);
        toast.error(err.toString(), {
          theme: "colored"
        });
      }
    })
}
