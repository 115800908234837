import { useRecoilState } from 'recoil'
import streamingListToBottomAtom from '../../recoil/streamingListToBottom'
import React, { useEffect, useState } from 'react'
import TrafficViewerStyles from './TrafficViewer.module.sass'
import useStreamingWebSocket from '../../hooks/useStreamingWebSocket'

const useStreamingList = () => {
  const { readyState } = useStreamingWebSocket()

  const [toBottom, setToBottom] = useRecoilState<boolean>(streamingListToBottomAtom)

  const [connectionTitle, setConnectionTitle] = useState('streaming paused')
  const [connectionIndicator, setConnectionIndicator] = useState(null)

  useEffect(() => {
    switch (readyState) {
    case WebSocket.OPEN:
      setConnectionTitle('streaming live traffic')
      setConnectionIndicator(
        <div
          className={`${TrafficViewerStyles.indicatorContainer} ${TrafficViewerStyles.greenIndicatorContainer}`}
        >
          <div
            className={`${TrafficViewerStyles.indicator} ${TrafficViewerStyles.greenIndicator}`}
          />
        </div>
      )
      break
    default:
      setConnectionTitle('streaming paused')
      setConnectionIndicator(
        <div
          className={`${TrafficViewerStyles.indicatorContainer} ${TrafficViewerStyles.redIndicatorContainer}`}
        >
          <div
            className={`${TrafficViewerStyles.indicator} ${TrafficViewerStyles.redIndicator}`}
          />
        </div>
      )
    }
  }, [readyState])

  return {
    toBottom,
    setToBottom,
    connectionTitle,
    connectionIndicator,
  }
}

export default useStreamingList
