import { useEffect, useRef } from 'react'
import { useRecoilState } from 'recoil'
import { appAuthEnabled, appCloudLicenseEnabled, appOidcAuthTypeEnabled, appSamlAuthTypeEnabled } from '../types/global'
import useLicense from './useLicense'
import resetLicenseTimersSignalAtom from '../recoil/resetLicenseTimersSignal'
import { checkSamlAuth } from '../components/UI/Auth/SamlAuth/SamlAuth'

const useLicenseUpdater = () => {
  const { resolveCloudLicense, getLicense, setHubPublicNetworkOk } = useLicense()

  const licenseTimeout = useRef<NodeJS.Timeout | null>(null)
  const licenseInterval = useRef<NodeJS.Timeout | null>(null)

  const setLicenseTimers = (immediate = false) => {
    if (immediate) {
      getLicense()
    } else {
      clearTimeout(licenseTimeout.current)
      licenseTimeout.current = setTimeout(getLicense, 2000)
    }

    clearInterval(licenseInterval.current)
    licenseInterval.current = setInterval(() => {
      getLicense()
    }, 3000)
  }

  const [resetLicenseTimersSignal, setResetLicenseTimersSignal] = useRecoilState(resetLicenseTimersSignalAtom)

  useEffect(() => {
    if (resetLicenseTimersSignal !== null) {
      if (appCloudLicenseEnabled() && appOidcAuthTypeEnabled()) {
        let status: number

        resolveCloudLicense()
          .then((response) => {
            status = response.status
            return response.json()
          })
          .then((response) => {
            setHubPublicNetworkOk(response.networkOk)
          }).catch(() => {
            if (status === 401) {
              return
            }

            setHubPublicNetworkOk(false)
          }).finally(() => {
            setLicenseTimers(true)
          })
      } else {
        setLicenseTimers()
      }

      setResetLicenseTimersSignal(null)
    }
  }, [resetLicenseTimersSignal])

  useEffect(() => {
    const searchParams = new URLSearchParams(window.location.search)

    if (appCloudLicenseEnabled() && appOidcAuthTypeEnabled()) {
      if (!searchParams.has('descope-login-flow')) {
        let status: number

        resolveCloudLicense()
          .then((response) => {
            status = response.status
            return response.json()
          })
          .then((response) => {
            setHubPublicNetworkOk(response.networkOk)
          }).catch(() => {
            if (status === 401) {
              return
            }

            setHubPublicNetworkOk(false)
          }).finally(() => {
            setLicenseTimers(true)
          })
      }
    } else if (appAuthEnabled() && appSamlAuthTypeEnabled()) {
      if (checkSamlAuth()) {
        setLicenseTimers()
      }
    } else {
      setLicenseTimers()
    }

    return () => {
      clearTimeout(licenseTimeout.current)
      clearInterval(licenseInterval.current)
    }
  }, [])
}

export default useLicenseUpdater
