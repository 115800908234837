import React from 'react'
import { Button, Tooltip, Typography } from '@mui/material'
import { LinkContactUs } from '../../../consts'

import variables from '../../../variables.module.scss'

interface ContactUsButtonProps {
  size: 'small' | 'large' | 'medium'
}

export const ContactUsButton: React.FC<ContactUsButtonProps> = ({ size }) => {
  return (
    <Tooltip arrow placement='top' title='You will be directed to our contact form'>
      <Button
        className={`themeButton primary`}
        fullWidth
        variant='contained'
        size={size}
        sx={{ backgroundColor: variables.blueColor }}
        onClick={() => window.open(LinkContactUs, '_blank')}
      >
        <Typography
          variant='body1'
          fontSize={14}
          fontFamily={variables.textFontFamily}
          fontWeight={600}
          color='#FFFFFF'
        >
          Contact us
        </Typography>
      </Button>
    </Tooltip>
  )
}
