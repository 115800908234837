import { HubBaseUrl } from '../consts'
import { useRecoilState } from 'recoil'
import queryFiltersAtom from '../recoil/queryFilters'
import { QueryFilters } from '../types/queryFilters'
import { toast } from 'react-toastify'

export interface QueryValidationResponse extends Response {
  valid: boolean,
  message: string,
}

const useQueryFilters = () => {
  const [queryFilters, setQueryFilters] = useRecoilState<QueryFilters>(queryFiltersAtom)

  const fetchQueryFilters = () => {
    fetch(
      `${HubBaseUrl}/query/filters`,
      {
        method: 'GET',
        headers: {
          'X-Kubeshark-Capture': 'ignore',
        },
      },
    )
      .then(response => response.ok ? response : response.text().then(err => Promise.reject(err)))
      .then(response => response.json())
      .then(data => {
        setQueryFilters({...data, fetched: true})
      })
      .catch(err => {
        if (err.length > 0) {
          console.error(err)
        }
      });
  }

  const validateQueryFilter = (query: string): Promise<QueryValidationResponse> => {
    return fetch(
      `${HubBaseUrl}/query/validate?q=${encodeURIComponent(query)}`,
      {
        method: 'GET',
        headers: {
          'X-Kubeshark-Capture': 'ignore',
        },
      },
    )
      .then(response => response.ok ? response : response.text().then(err => Promise.reject(err)))
      .then(response => response.json())
      .catch(err => {
        console.error(err);
        toast.error(err.toString(), {
          theme: "colored"
        });
      });
  }

  return {
    queryFilters,
    fetchQueryFilters,
    validateQueryFilter
  }
}

export default useQueryFilters
