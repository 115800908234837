import React, { useEffect, useState } from 'react'
import { Box } from '@mui/material'
import variables from '../../../../../../variables.module.scss'

import SelectAllOutlinedIcon from '@mui/icons-material/SelectAllOutlined'
import FilterListRoundedIcon from '@mui/icons-material/FilterListRounded'
import { HubBaseUrl } from '../../../../../../consts'
import { toast } from 'react-toastify'
import useQueryFilters from '../../../../../../hooks/useQueryFilters'
import useDebounce from '../../../../../../hooks/useDebounce'
import { SettingInput } from '../../../SettingInput/SettingInput'

export const QueryFiltersView = () => {
  const [globalFilter, setGlobalFilter] = useState('')
  const [defaultFilter, setDefaultFilter] = useState('')

  const debouncedGlobalFilter = useDebounce(globalFilter, 400)
  const debouncedDefaultFilter = useDebounce(defaultFilter, 400)

  const [globalFilterChanging, setGlobalFilterChanging] = useState(false)
  const [globalFilterActive, setGlobalFilterActive] = useState(false)
  const [globalFilterError, setGlobalFilterError] = useState(false)

  const [defaultFilterChanging, setDefaultFilterChanging] = useState(false)
  const [defaultFilterActive, setDefaultFilterActive] = useState(false)
  const [defaultFilterError, setDefaultFilterError] = useState(false)

  const { queryFilters, fetchQueryFilters, validateQueryFilter } = useQueryFilters()

  const handleFilterChange = (filter: string, set: (value: unknown) => void, setError: (state: boolean) => void) => {
    validateQueryFilter(filter)
      .then((data) => {
        if (data.valid) {
          setError(false)
        } else {
          setError(true)
        }
      })
  }

  const handleGlobalFilterChange = (val: string) => {
    setGlobalFilter(val)
  }

  useEffect(() => {
    handleFilterChange(debouncedGlobalFilter, setGlobalFilter, setGlobalFilterError)
  }, [debouncedGlobalFilter])

  const handleDefaultFilterChange = (val: string) => {
    setDefaultFilter(val)
  }

  useEffect(() => {
    handleFilterChange(debouncedDefaultFilter, setDefaultFilter, setDefaultFilterError)
  }, [debouncedDefaultFilter])

  useEffect(() => {
    if (queryFilters.fetched) {
      setGlobalFilter(queryFilters.global)
      setDefaultFilter(queryFilters.default)
    }
  }, [queryFilters])

  const postFilter = (
    filterType: string,
    filter: string,
    setError: (state: boolean) => void,
    setActive: (state: boolean) => void,
    set: (value: unknown) => void,
    setChanging: (state: boolean) => void,
    errorText: string
  ) => {
    fetch(
      `${HubBaseUrl}/query/filter`,
      {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
          'X-Kubeshark-Capture': 'ignore',
        },
        body: JSON.stringify({ filterType: filterType, filter: filter }),
      }
    )
      .then((response) =>
        response.ok
          ? response
          : response.text().then((err) => Promise.reject(err))
      )
      .then(response => {
        if (response.status === 200) {
          setError(false)
          setActive(true)
          set(filter)
          fetchQueryFilters()
        }
      })
      .catch((err) => {
        toast.error(`${errorText} (${err})`, {
          theme: 'colored'
        });
        setError(true)
      }).finally(() => setChanging(false));
  }

  const postGlobalFilter = () => {
    postFilter(
      'global',
      globalFilter,
      setGlobalFilterError,
      setGlobalFilterActive,
      setGlobalFilter,
      setGlobalFilterChanging,
      'Invalid global filter',
    )
  }

  const postDefaultFilter = () => {
    postFilter(
      'default',
      defaultFilter,
      setDefaultFilterError,
      setDefaultFilterActive,
      setDefaultFilter,
      setDefaultFilterChanging,
      'Invalid default filter',
    )
  }

  return (
    <Box
      boxSizing='border-box'
      width='100%'
      display='flex'
      alignItems='center'
      flexDirection='column'
      gap='16px'
    >
      <SettingInput
        icon={<SelectAllOutlinedIcon htmlColor={variables.blueColor} />}
        title='Global filter'
        placeholder='Enter KFL query string'
        gridEnabled={false}
        handleValueChange={handleGlobalFilterChange}
        value={globalFilter}
        changing={globalFilterChanging}
        setChanging={setGlobalFilterChanging}
        active={globalFilterActive}
        error={globalFilterError}
        postValue={postGlobalFilter}
      />
      <SettingInput
        icon={<FilterListRoundedIcon htmlColor={variables.blueColor} />}
        title='Default filter'
        placeholder='Enter KFL query string'
        gridEnabled={false}
        handleValueChange={handleDefaultFilterChange}
        value={defaultFilter}
        changing={defaultFilterChanging}
        setChanging={setDefaultFilterChanging}
        active={defaultFilterActive}
        error={defaultFilterError}
        postValue={postDefaultFilter}
      />
    </Box>
  )
}
