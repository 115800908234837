import React from 'react'

import { Box, Typography } from '@mui/material'
import ErrorIcon from '@mui/icons-material/Error';

import variables from '../../../variables.module.scss'

interface LicenseIssueCardProps {
  theme: 'info' | 'warning' | 'error'
  title: string
  text: string | JSX.Element
}

const themeColors = {
  info: {
    color: variables.blueColor,
    bgColor: variables.lightBlueColor
  },
  warning: {
    color: variables.warningColor,
    bgColor: variables.lightWarningColor,
  },
  error: {
    color: variables.upgradeColor,
    bgColor: variables.lightUpgradeColor,
  },
}

export const LicenseIssueCard: React.FC<LicenseIssueCardProps> = ({ theme = 'info', title, text }) => {
  return (
    <Box
      padding='10px'
      borderRadius='4px'
      mb='10px'
      border={`1px solid ${themeColors[theme].color}`}
      bgcolor={themeColors[theme].bgColor}
    >
      <Box display='flex' alignItems='center' gap='10px' mb='10px'>
        <ErrorIcon fontSize='medium' htmlColor={themeColors[theme].color} />
        <Typography
          variant='h2'
          color={themeColors[theme].color}
          fontFamily={variables.textFontFamily}
          fontWeight={600}
          fontSize={20}
        >
          {title}
        </Typography>
      </Box>
      {typeof text === 'string' ? (
        <Typography
          variant='body1'
          color={variables.fontColor}
          fontFamily={variables.textFontFamily}
          fontWeight={600}
          fontSize={14}
          ml='5px'
        >
          {text}
        </Typography>
      ) : text}
    </Box>
  )
}
