let stateChangeTime = 0

const useThrottling = (callback, throttleMs: number) => {
  if (typeof callback === 'function') {
    return () => {
      const secsElapsed = Math.floor(Date.now() - stateChangeTime)
      if (stateChangeTime > 0 && secsElapsed <= throttleMs) {
        return () => false
      }
      stateChangeTime = Date.now()
      callback()
    }
  } else {
    return () => false
  }
}

export default useThrottling
