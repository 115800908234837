import React, { CSSProperties } from 'react'

import {
  ClickAwayListener,
  Popper,
  Grow,
  PopperPlacementType
} from '@mui/material'

import { Dropdown as MuiBaseDropdown } from '@mui/base'

interface DropdownProps {
  open: boolean
  setOpen: (state: boolean) => void
  anchorElement: JSX.Element
  anchorRef: React.MutableRefObject<HTMLAnchorElement>
  anchorWrapStyles?: CSSProperties
  children: React.ReactElement
  placement: PopperPlacementType
  popperModifiers: Array<object>
  growFrom: string
}

export const Dropdown: React.FC<DropdownProps> = ({
  open,
  setOpen,
  anchorElement,
  anchorRef,
  anchorWrapStyles,
  children,
  placement,
  popperModifiers,
  growFrom
}): React.ReactElement => {
  return (
    <ClickAwayListener
      onClickAway={() => {
        setOpen(false)
      }}
    >
      <div style={{ position: 'relative', ...anchorWrapStyles }}>
        <MuiBaseDropdown open={open}>
          {anchorElement}
          <Popper
            open={open}
            anchorEl={anchorRef.current}
            role={undefined}
            placement={placement}
            transition
            modifiers={popperModifiers}
            sx={{ zIndex: 1300 }}
          >
            {({ TransitionProps }) => (
              <Grow
                {...TransitionProps}
                style={{
                  transformOrigin: growFrom
                }}
                onClick={(e) => e.stopPropagation()}
              >
                {children}
              </Grow>
            )}
          </Popper>
        </MuiBaseDropdown>
      </div>
    </ClickAwayListener>
  )
}
