import { LocalStorageKey } from '../../../consts'
import { SessionDetails } from '../../../types/auth'

export const setAuthSessionDetails = (details: SessionDetails) => {
  localStorage.setItem(
    LocalStorageKey.AuthSessionDetails,
    JSON.stringify(details)
  )
}

export const getAuthSessionDetails = (): SessionDetails => {
  const detailsStr = localStorage.getItem(LocalStorageKey.AuthSessionDetails)
  return JSON.parse(detailsStr)
}

export const removeAuthSessionDetails = () => {
  localStorage.removeItem(LocalStorageKey.AuthSessionDetails)
}

