import React from 'react'

export const ExpandPanelIcon: React.FC = () => {
  return (
    <svg
      width='30'
      height='30'
      viewBox='0 0 30 30'
      fill='none'
      xmlns='http://www.w3.org/2000/svg'
    >
      <rect y='0.379883' width='30' height='30' rx='6' fill='#F7F9FC' />
      <path
        d='M9.65583 5.37988L6.93686 5.37988C6.53092 5.37988 6.1416 5.57743 5.85456 5.92906C5.56751 6.28069 5.40625 6.7576 5.40625 7.25488L5.40625 23.5049C5.40625 24.0022 5.56751 24.4791 5.85456 24.8307C6.1416 25.1823 6.53092 25.3799 6.93686 25.3799L10.4063 25.3799'
        stroke='#416CDE'
        strokeWidth='2'
        strokeMiterlimit='2.3662'
        strokeLinecap='round'
      />
      <path
        d='M24.5312 23.6152L24.5312 7.14459C24.5312 6.16997 23.616 5.37988 23.0312 5.37988L13.5901 5.37988C13.0053 5.37988 12.5312 6.16997 12.5312 7.14459L12.5312 23.6152C12.5312 24.5898 13.0053 25.3799 13.5901 25.3799L23.0312 25.3799C23.616 25.3799 24.5312 24.5898 24.5312 23.6152Z'
        fill='white'
        stroke='#BCCEFD'
        strokeWidth='2'
        strokeLinecap='round'
        strokeLinejoin='round'
        strokeDasharray='3 3'
      />
      <path
        d='M13.6919 12.001C13.6919 11.8016 13.5734 11.6212 13.3904 11.5421C13.2074 11.4629 12.9948 11.5001 12.8495 11.6366L9.65757 14.6366C9.55702 14.7311 9.5 14.863 9.5 15.001C9.5 15.139 9.55702 15.2708 9.65757 15.3653L12.8495 18.3653C12.9948 18.5019 13.2074 18.5391 13.3904 18.4599C13.5734 18.3807 13.6919 18.2004 13.6919 18.001L13.6919 16.493L19 16.493C19.1326 16.493 19.2598 16.4404 19.3536 16.3466C19.4473 16.2528 19.5 16.1256 19.5 15.993L19.5 14.0089C19.5 13.7328 19.2761 13.5089 19 13.5089L13.6919 13.5089L13.6919 12.001Z'
        fill='#416CDE'
        stroke='white'
        strokeLinejoin='round'
      />
    </svg>
  )
}
