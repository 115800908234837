import { atom } from "recoil";
import { appTimezone } from "../../types/global";
import { LocalStorageKey } from "../../consts";

import Moment from 'moment-timezone';

const resolveDefaultTimezone = () => {
  if (localStorage.getItem(LocalStorageKey.Timezone) !== null) {
    return localStorage.getItem(LocalStorageKey.Timezone)
  }

  if (appTimezone() !== '') {
    return appTimezone()
  }

  return Moment.tz.guess(true)
}

const timezoneAtom = atom({
  key: "timezoneAtom",
  default: resolveDefaultTimezone()
});

export default timezoneAtom;
