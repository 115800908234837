import React, { useEffect, useRef, useState } from 'react'
import { Box, Tooltip } from '@mui/material'

import ExpandMoreRoundedIcon from '@mui/icons-material/ExpandMoreRounded';

import variables from '../../../../variables.module.scss'
import styles from './ConsoleTab.module.sass'
import { Dropdown } from '../../../UI/Dropdown/Dropdown'

interface Tab {
  title?: string
  index?: number
  selected?: boolean
  onSelection?: (index: number, isDropdownTab?: boolean) => void
}

interface ConsoleTabProps extends Tab {
  asDropdown?: boolean
  dropdownTabs?: Tab[]
}

export const ConsoleTab: React.FC<ConsoleTabProps> = ({
  title,
  index,
  selected = false,
  onSelection,
  asDropdown = false,
  dropdownTabs = []
}) => {
  const [dropdownOpen, setDropdownOpen] = useState(false)

  const dropdownAnchorRef = useRef(null)

  const [selectedDropdownTabTitle, setSelectedDropdownTabTitle] = useState(null)

  useEffect(() => {
    dropdownTabs.map((tab) => {
      if (tab.selected) {
        setSelectedDropdownTabTitle(tab.title)
      }
    })
  }, [dropdownTabs])

  return (
    <Tooltip title={
      !asDropdown ? (
        selected ? `Showing logs for selected script` : `Click to show logs for this script`
      ) : ''
    } placement='bottom' arrow>
      <Box
        className={`${styles.ConsoleTab} ${selected ? styles.ConsoleTabSelected : ''}`}
        onClick={() => asDropdown ? setDropdownOpen(!dropdownOpen) : onSelection(index)}
      >
        {!asDropdown && (
          <p className={`${styles.ConsoleTabText} ${asDropdown ? styles.DropdownTabTitle : ''}`}>
            {title}
          </p>
        )}
        {asDropdown && (
          <Dropdown
            open={dropdownOpen}
            setOpen={setDropdownOpen}
            anchorElement={
              <Box
                boxSizing='border-box'
                width='100%'
                display='flex'
                alignItems='center'
                justifyContent='space-between'
                ref={dropdownAnchorRef}
              >
                {selectedDropdownTabTitle === null && (
                  <p className={`${styles.ConsoleTabText} ${asDropdown ? styles.DropdownTabTitle : ''}`}>
                    + {dropdownTabs.length} script logs
                  </p>
                )}
                {selectedDropdownTabTitle !== null && (
                  <p className={`${styles.ConsoleTabText} ${asDropdown ? styles.DropdownTabTitle : ''}`}>
                    {selectedDropdownTabTitle}
                  </p>
                )}
                <ExpandMoreRoundedIcon htmlColor={variables.lighterGrayColor} sx={{ marginRight: '10px' }} />
              </Box>
            }
            anchorRef={dropdownAnchorRef}
            anchorWrapStyles={{
              display: 'flex',
              alignItems: 'center',
              justifyContent: 'space-between',
              width: '100%'
            }}
            placement='bottom-start'
            popperModifiers={[
              {
                name: 'offset',
                options: {
                  offset: [10, 10]
                }
              }
            ]}
            growFrom='right top'
          >
            <Box
              boxSizing='border-box'
              padding='12px'
              display='flex'
              alignItems='center'
              flexDirection='column'
              gap='10px'
              borderRadius='4px'
              border={`1px solid ${variables.slateColor}`}
              bgcolor={variables.githubEditorBackgroundColorLight}
              boxShadow={variables.lightShadow}
              maxHeight='400px'
              overflow='auto'
            >
              {dropdownTabs.map((tab: Tab) => {
                return (
                  <Box
                    className={styles.DropdownTab}
                    onClick={() => {
                      tab.onSelection(tab.index, true)
                      setDropdownOpen(false)
                    }}
                    sx={{
                      backgroundColor: tab.selected ? `${variables.githubEditorBackgroundColor} !important` : null,
                      outline: tab.selected ? `1px solid ${variables.slateColor}` : null
                    }}
                  >
                    <Box className={styles.DropdownTabText}>
                      {tab.title}
                    </Box>
                  </Box>
                )
              })}
            </Box>
          </Dropdown>
        )}
      </Box>
    </Tooltip>
  )
}
