import React, { FC, useEffect, useMemo, useRef, useState } from "react";
import styles from './Filters.module.sass';
import {
  Button,
  Grid,
  Modal,
  Box,
  Typography,
  Backdrop,
  Fade,
  Divider,
  debounce,
  Link,
  IconButton,
  Badge,
  Tooltip,
  ButtonBase,
  CircularProgress
} from '@mui/material'
import CodeEditor from '@uiw/react-textarea-code-editor';
import MenuBookIcon from '@mui/icons-material/MenuBook';
import { SyntaxHighlighter } from "../UI/SyntaxHighlighter";
import filterUIExample1 from "./assets/filter-ui-example-1.png"
import filterUIExample2 from "./assets/filter-ui-example-2.png"
import useKeyPress from "../../hooks/useKeyPress"
import shortcutsKeyboard from "../../configs/shortcutsKeyboard"
import { useRecoilState, useRecoilValue, useSetRecoilState } from "recoil";
import queryAtom from "../../recoil/query";
import queryBuildAtom from "../../recoil/queryBuild";
import queryBackgroundColorAtom from "../../recoil/queryBackgroundColor";
import { ColorGreen, ColorRed, ColorWhite } from "../../consts";
import { useNavigate, useLocation } from 'react-router-dom';
import { CreateRecordingDialog } from "../CreateRecordingDialog/CreateRecordingDialog";
import variables from '../../variables.module.scss';
import GroupRoundedIcon from '@mui/icons-material/GroupRounded';
import SelectAllOutlinedIcon from '@mui/icons-material/SelectAllOutlined';
import './Filters.sass';
import { AutoAppliedFilter } from "./AutoAppliedFilter/AutoAppliedFilter";
import appliedPodRegexAtom from "../../recoil/appliedPodRegex/atom";
import appliedPodNamespacesAtom from "../../recoil/appliedPodNamespaces/atom";
import FilterListRoundedIcon from '@mui/icons-material/FilterListRounded';
import trafficPlaybackAtom, { TRAFFIC_PLAYBACK_OK } from "../../recoil/trafficPlayback/atom";
import entryDetailsOpenAtom from "../../recoil/entryDetailsOpen/atom";
import focusedStreamAtom from "../../recoil/focusedStream/atom";
import focusedRecordAtom from "../../recoil/focusedRecord/atom";
import focusedItemAtom from "../../recoil/focusedItem/atom";
import entriesAtom from "../../recoil/entries/atom";
import { ExpandPanelIcon } from "../UI/Icons/ExpandPanelIcon";
import { FullscreenViewButton } from "../UI/FullscreenView/FullscreenViewButton";
import entryDetailsViewModeAtom from "../../recoil/entryDetailsViewMode";
import { useAuth } from "../UI/Auth/Auth";
import PasswordIcon from '@mui/icons-material/Password'
import DataArrayIcon from '@mui/icons-material/DataArray'
import MemoryIcon from '@mui/icons-material/Memory';
import appliedPodBpfOverrideAtom from '../../recoil/appliedPodBpfOverride'
import { appBpfOverrideDisabled, appDissectorsUpdatingEnabled, appTargetedPodsUpdateDisabled } from '../../types/global'
import focusedEntryAtom from '../../recoil/focusedEntry'
import enabledDissectorsAtom from '../../recoil/enabledDissectors'
import { CircuitBoardIcon } from '../UI/Icons/CircuitBoardIcon'
import appliedPodExcludedNamespacesAtom from '../../recoil/appliedPodExcludedNamespaces'
import { HtmlTooltip } from '../UI/HtmlTooltip/HtmlTooltip'
import useElementRouter from '../../hooks/useElementRouter'
import useTargeting from '../../hooks/useTargeting'
import { authorizeAction, AUTHZ_ACTIONS } from '../UI/Auth/SamlAuth/Authorization'
import useQueryFilters from '../../hooks/useQueryFilters'
import { Entry } from '../EntryListItem/Entry'
import { resetHeapCache } from '../../helpers/cacher/cacher'
import useStreamingWebSocket, { THROTTLE_WS_STATE_CHANGE_MS } from '../../hooks/useStreamingWebSocket'

interface CodeEditorWrap {
  onQueryChange?: (q: string) => void
  onValidationChanged?: (event: OnQueryChange) => void
  hideAutoAppliedFilters?: boolean
  hideEntryDetailsBtn?: boolean
}

export const Filters: React.FC<CodeEditorWrap> = ({ onQueryChange, hideEntryDetailsBtn }) => {
  return <div className={styles.container}>
    <QueryForm
      onQueryChange={onQueryChange}
      hideEntryDetailsBtn={hideEntryDetailsBtn}
    />
  </div>;
};

type OnQueryChange = { valid: boolean, message: string, query: string }

export const modalStyle = {
  position: 'absolute',
  top: '10%',
  left: '50%',
  transform: 'translate(-50%, 0%)',
  width: '80vw',
  bgcolor: 'background.paper',
  borderRadius: '5px',
  boxShadow: 24,
  outline: "none",
  p: 4,
  color: '#000',
};

interface AutoAppliedFiltersProps {
  show: boolean
}

const AutoAppliedFilters: FC<AutoAppliedFiltersProps> = ({ show }) => {
  const { queryFilters, fetchQueryFilters } = useQueryFilters()

  const appliedPodRegex = useRecoilValue(appliedPodRegexAtom)
  const appliedPodNamespaces = useRecoilValue(appliedPodNamespacesAtom)
  const appliedPodExcludedNamespaces = useRecoilValue(appliedPodExcludedNamespacesAtom)
  const appliedPodBpfOverride = useRecoilValue(appliedPodBpfOverrideAtom)
  const enabledDissectors = useRecoilValue(enabledDissectorsAtom)
  const [dissectorsArray, setDissectorsArray] = useState([])
  const [excludedDissectorsArray, setExcludeDissectorsArray] = useState([])

  const { navigateToElement } = useElementRouter()

  useEffect(() => {
    const dissectors = Object.keys(enabledDissectors).filter((dissector) => {
      if (enabledDissectors[dissector]) {
        return dissector
      }
    })

    const excludedDissectors = Object.keys(enabledDissectors).filter((dissector) => {
      if (!enabledDissectors[dissector]) {
        return dissector
      }
    })

    setDissectorsArray(dissectors)
    setExcludeDissectorsArray(excludedDissectors)
  }, [enabledDissectors])

  const trafficPlayback = useRecoilValue(trafficPlaybackAtom)

  const { shouldAuthenticate } = useAuth()

  useEffect(() => {
    if (trafficPlayback !== TRAFFIC_PLAYBACK_OK) {
      return
    }

    if (shouldAuthenticate) {
      return
    }

    fetchQueryFilters()
  }, [trafficPlayback])

  if (!show) {
    return <></>
  }

  return (
    <Box className={styles.AutoAppliedFilters}>
      {queryFilters.saml.length > 0 && <AutoAppliedFilter
        name='SAML role filter'
        icon={<GroupRoundedIcon fontSize='small' htmlColor={variables.blueColor} sx={{ transition: 'all 0.2s' }} />}
        filter={queryFilters.saml}
      />}
      {queryFilters.global.length > 0 && <AutoAppliedFilter
        editable
        name='Global filter'
        icon={<SelectAllOutlinedIcon fontSize='small' htmlColor={variables.blueColor} sx={{ transition: 'all 0.2s' }} />}
        filter={queryFilters.global}
        onEdit={() => navigateToElement('settings:query-filters')}
      />}
      {queryFilters.default.length > 0 && <AutoAppliedFilter
        editable
        name='Default filter'
        icon={<FilterListRoundedIcon fontSize='small' htmlColor={variables.blueColor} sx={{ transition: 'all 0.2s' }} />}
        filter={queryFilters.default}
        onEdit={() => navigateToElement('settings:query-filters')}
      />}
      <AutoAppliedFilter
        editable={!appTargetedPodsUpdateDisabled()}
        authorized={authorizeAction(AUTHZ_ACTIONS.CAN_UPDATE_TARGETED_PODS)}
        name='Pod regex'
        icon={<PasswordIcon fontSize='small' htmlColor={variables.blueColor} sx={{ transition: 'all 0.2s' }} />}
        filter={appliedPodRegex.length > 0 ? appliedPodRegex : 'all'}
        onEdit={() => navigateToElement('settings:targeting')}
      />
      <AutoAppliedFilter
        editable={!appTargetedPodsUpdateDisabled()}
        authorized={authorizeAction(AUTHZ_ACTIONS.CAN_UPDATE_TARGETED_PODS)}
        name='Namespaces'
        icon={<DataArrayIcon fontSize='small' htmlColor={variables.blueColor} sx={{ transition: 'all 0.2s' }} />}
        filter={appliedPodNamespaces.length > 0 ? `${appliedPodNamespaces.join(', ')}` : 'all'}
        onEdit={() => navigateToElement('settings:targeting')}
      />
      <AutoAppliedFilter
        editable={!appTargetedPodsUpdateDisabled()}
        authorized={authorizeAction(AUTHZ_ACTIONS.CAN_UPDATE_TARGETED_PODS)}
        name='Excluded Namespaces'
        icon={<DataArrayIcon fontSize='small' htmlColor={variables.blueColor} sx={{ transition: 'all 0.2s' }} />}
        filter={appliedPodExcludedNamespaces.length > 0 ? `${appliedPodExcludedNamespaces.join(', ')}` : 'none'}
        onEdit={() => navigateToElement('settings:targeting')}
      />
      {!appBpfOverrideDisabled() && appliedPodBpfOverride.length > 0 && <AutoAppliedFilter
        editable={!appTargetedPodsUpdateDisabled()}
        authorized={authorizeAction(AUTHZ_ACTIONS.CAN_UPDATE_TARGETED_PODS)}
        name='BPF Override Expression'
        icon={<MemoryIcon fontSize='small' htmlColor={variables.blueColor} sx={{ transition: 'all 0.2s' }} />}
        filter={appliedPodBpfOverride}
        onEdit={() => navigateToElement('settings:targeting')}
      />}
      {appDissectorsUpdatingEnabled() && dissectorsArray.length > 0 && (
        <AutoAppliedFilter
          editable
          name='Dissectors'
          icon={<CircuitBoardIcon size='small' stroke={variables.blueColor} />}
          filter={dissectorsArray.join(', ')}
          onEdit={() => navigateToElement('settings:dissectors')}
        />
      )}
      {appDissectorsUpdatingEnabled() && excludedDissectorsArray.length > 0 && (
        <AutoAppliedFilter
          editable
          name='Excluded Dissectors'
          icon={<CircuitBoardIcon size='small' stroke={variables.blueColor} />}
          filter={excludedDissectorsArray.join(', ')}
          onEdit={() => navigateToElement('settings:dissectors')}
        />
      )}
    </Box>
  )
}

const autoAppliedFiltersPresentedKey = 'kubeshark.autoAppliedFiltersPresented'


export const CodeEditorWrap: FC<CodeEditorWrap> = ({ onQueryChange, onValidationChanged, hideAutoAppliedFilters }) => {
  const [queryBackgroundColor, setQueryBackgroundColor] = useRecoilState(queryBackgroundColorAtom);

  const queryBuild = useRecoilValue(queryBuildAtom);
  const trafficPlayback = useRecoilValue(trafficPlaybackAtom)

  const [showAppliedFilters, setShowAppliedFilters] = useState(false)

  const { validateQueryFilter } = useQueryFilters()
  const { fetchTargetSettings } = useTargeting()

  useEffect(() => {
    if (showAppliedFilters) {
      fetchTargetSettings()
    }
  }, [showAppliedFilters])

  const handleQueryChange = useMemo(
    () =>
      debounce(async (query: string) => {
        if (!query) {
          setQueryBackgroundColor(ColorWhite);
          onValidationChanged && onValidationChanged({ query: query, message: "", valid: true });
        } else {
          validateQueryFilter(query)
            .then((data) => {
              if (data.valid) {
                setQueryBackgroundColor(ColorGreen);
              } else {
                setQueryBackgroundColor(ColorRed);
              }
              onValidationChanged && onValidationChanged({ query: query, message: data.message, valid: data.valid })
            })
        }
      }, 100),
    [onValidationChanged]
  ) as (query: string) => void;

  const { shouldAuthenticate } = useAuth()

  useEffect(() => {
    if (trafficPlayback !== TRAFFIC_PLAYBACK_OK) {
      return
    }

    if (shouldAuthenticate) {
      return
    }

    handleQueryChange(queryBuild);
  }, [queryBuild, handleQueryChange]);

  useEffect(() => {
    let postponeTimeout: NodeJS.Timeout = null;
    let showTimeout: NodeJS.Timeout = null

    if (localStorage.getItem(autoAppliedFiltersPresentedKey) === null) {
      postponeTimeout = setTimeout(() => {
        setShowAppliedFilters(true)
        showTimeout = setTimeout(() => {
          setShowAppliedFilters(false)
          localStorage.setItem(autoAppliedFiltersPresentedKey, 'true')
        }, 6000)
      }, 5000)
    }

    return () => {
      clearTimeout(postponeTimeout)
      clearTimeout(showTimeout)
    }
  }, [])

  return (
    <Box width='100%' display='flex' borderRadius='2px' className={`queryInputContainer${hideAutoAppliedFilters ? '--noAutoAppliedFilters' : ''}`}>
      <CodeEditor
        autoFocus
        value={queryBuild}
        language="py"
        data-color-mode='light'
        placeholder="Kubeshark Filter Syntax"
        onChange={(event) => onQueryChange(event.target.value)}
        padding={8}
        style={{
          width: '100%',
          fontSize: 14,
          borderRadius: '2px',
          backgroundColor: `${queryBackgroundColor}`,
          fontFamily: 'ui-monospace,SFMono-Regular,SF Mono,Consolas,Liberation Mono,Menlo,monospace',
        }}
      />
      {!hideAutoAppliedFilters && (
        <Box onMouseLeave={() => setShowAppliedFilters(false)}>
          <HtmlTooltip
            open={showAppliedFilters}
            title={
              <AutoAppliedFilters show={showAppliedFilters} />
            }
            placement='bottom-start'
            arrow
          >
            <IconButton
              size='small'
              sx={{
                height: '32px',
                width: '32px',
                borderRadius: '2px',
                backgroundColor: variables.mainBackgroundColor
              }}
              onMouseEnter={() => setShowAppliedFilters(true)}
            >
              <Badge variant='dot' color='primary' invisible={showAppliedFilters}>
                <FilterListRoundedIcon fontSize='small' htmlColor={showAppliedFilters ? variables.blueColor : variables.grayColor} />
              </Badge>
            </IconButton>
          </HtmlTooltip>
        </Box>
      )}
    </Box>
  )
}

export const QueryForm: React.FC<CodeEditorWrap> = ({ onQueryChange, onValidationChanged, hideEntryDetailsBtn = false }) => {

  const formRef = useRef<HTMLFormElement>(null);

  const [openModal, setOpenModal] = useState(false);
  const [applyClicked, setApplyClicked] = useState(false)

  const queryBuild = useRecoilValue(queryBuildAtom);
  const setQuery = useSetRecoilState(queryAtom);
  const setEntries = useSetRecoilState(entriesAtom)

  const handleOpenModal = () => setOpenModal(true);
  const handleCloseModal = () => setOpenModal(false);

  const navigate = useNavigate()
  const location = useLocation()
  const { reopen } = useStreamingWebSocket()

  useEffect(() => {
    if (applyClicked) {
      setTimeout(() => {
        setApplyClicked(false)
      }, THROTTLE_WS_STATE_CHANGE_MS)
    }
  }, [applyClicked])

  const handleSubmit = (e) => {
    e.preventDefault();

    if (applyClicked) {
      return
    }

    setApplyClicked(true)

    setQuery(queryBuild)
    setFocusedEntry(null)
    setFocusedItem(null);
    setEntries(new Map<string, Entry>())
    resetHeapCache()

    navigate({ pathname: location.pathname, search: `q=${encodeURIComponent(queryBuild)}` });
    reopen();
  }

  useKeyPress(shortcutsKeyboard.ctrlEnter, handleSubmit, formRef.current);

  const [entryDetailsOpen, setEntryDetailsOpen] = useRecoilState(entryDetailsOpenAtom)

  const setFocusedEntry = useSetRecoilState(focusedEntryAtom)
  const [focusedItem, setFocusedItem] = useRecoilState(focusedItemAtom);

  const entries = useRecoilValue(entriesAtom)
  const setFocusedStream = useSetRecoilState(focusedStreamAtom);
  const setFocusedRecord = useSetRecoilState(focusedRecordAtom);

  const handleOpenEntryDetails = () => {
    if (focusedItem === null && entries[0] !== undefined) {
      setFocusedEntry(entries[0]);
      setFocusedItem(entries[0].id);
      setFocusedStream(entries[0].stream);
      setFocusedRecord(entries[0].record);
    }

    setEntryDetailsOpen(true)
  }

  const entryDetailsViewMode = useRecoilValue(entryDetailsViewModeAtom)

  return <React.Fragment>
    <form
      ref={formRef}
      onSubmit={handleSubmit}
      style={{
        width: '100%',
      }}
    >
      <Grid container spacing={1.5} wrap="nowrap">
        <Grid
          item
          xs={11}
          style={{
            maxHeight: '25vh',
            overflowY: 'auto',
          }}
        >
          <label>
            <CodeEditorWrap onQueryChange={onQueryChange} onValidationChanged={onValidationChanged} />
          </label>
        </Grid>
        <Grid item xs="auto">
          <Button
            type="submit"
            variant="contained"
            className={styles.bigButton}
            style={{
              width: '70px',
              height: '26px',
              opacity: applyClicked ? 0.8 : 1,
              pointerEvents: applyClicked ? 'none' : 'all',
            }}
          >
            {applyClicked ? (
              <CircularProgress size={14} color='inherit' />
            ) : 'Apply'}
          </Button>
          <Button
            title="Open Filtering Guide (Cheatsheet)"
            variant="contained"
            color="primary"
            className={styles.smallButton}
            onClick={handleOpenModal}
          >
            <MenuBookIcon fontSize="inherit"></MenuBookIcon>
          </Button>
          <CreateRecordingDialog buttonClassName={styles.smallButton} />
        </Grid>
        {!hideEntryDetailsBtn && !entryDetailsOpen && (<>
          <Grid item xs="auto">
            <Tooltip
              arrow
              placement='left'
              title='Expand API calls details'
            >
              <ButtonBase
                onClick={handleOpenEntryDetails}
                sx={{ borderRadius: '6px' }}
              >
                <Box sx={{ height: '30px', transform: entryDetailsViewMode === 'drawer' ? 'rotate(90deg)' : null }}>
                  <ExpandPanelIcon />
                </Box>
              </ButtonBase>
            </Tooltip>
          </Grid>
          <Grid item xs="auto">
            <FullscreenViewButton />
          </Grid>
        </>)}
      </Grid>
    </form>

    <Modal
      aria-labelledby="transition-modal-title"
      aria-describedby="transition-modal-description"
      open={openModal}
      onClose={handleCloseModal}
      closeAfterTransition
      BackdropComponent={Backdrop}
      BackdropProps={{
        timeout: 500,
      }}
      style={{ overflow: 'auto' }}
    >
      <Fade in={openModal}>
        <Box sx={modalStyle}>
          <Typography id="modal-modal-title" variant="h5" component="h2" style={{ textAlign: 'center' }}>
            Filtering Guide (Cheatsheet)
          </Typography>
          <Typography component={'span'} id="modal-modal-description">
            <p>Kubeshark has a rich filtering syntax that let&apos;s you query the results both flexibly and efficiently.</p>
            <p>Here are some examples that you can try;</p>
          </Typography>
          <Grid container>
            <Grid item xs style={{ margin: "10px" }}>
              <Typography id="modal-modal-description">
                This is a simple query that matches to HTTP packets with request path &quot;catalogue&quot;:
              </Typography>
              <SyntaxHighlighter
                showLineNumbers={false}
                code={`http and request.path == "/catalogue"`}
                language="python"
              />
              <Typography id="modal-modal-description">
                The same query can be negated for HTTP path and written like this:
              </Typography>
              <SyntaxHighlighter
                showLineNumbers={false}
                code={`http and request.path != "/catalogue"`}
                language="python"
              />
              <Typography id="modal-modal-description">
                The syntax supports regular expressions. Here is a query that matches the HTTP requests that send JSON to a server:
              </Typography>
              <SyntaxHighlighter
                showLineNumbers={false}
                code={`http and request.headers["Accept"] == r"application/json.*"`}
                language="python"
              />
              <Typography id="modal-modal-description">
                Here is another query that matches HTTP responses with status code 4xx:
              </Typography>
              <SyntaxHighlighter
                showLineNumbers={false}
                code={`http and response.status == r"4.*"`}
                language="python"
              />
              <Typography id="modal-modal-description">
                The same exact query can be as integer comparison:
              </Typography>
              <SyntaxHighlighter
                showLineNumbers={false}
                code={`http and response.status >= 400`}
                language="python"
              />
              <Typography id="modal-modal-description">
                The results can be queried based on their timestamps:
              </Typography>
              <SyntaxHighlighter
                showLineNumbers={false}
                code={`timestamp < datetime("10/28/2021, 9:13:02.905 PM")`}
                language="python"
              />
            </Grid>
            <Divider className={styles.divider1} orientation="vertical" flexItem />
            <Grid item xs style={{ margin: "10px" }}>
              <Typography id="modal-modal-description">
                Since Kubeshark supports various protocols like gRPC, AMQP, Kafka and Redis. It&apos;s possible to write complex queries that match multiple protocols like this:
              </Typography>
              <SyntaxHighlighter
                showLineNumbers={false}
                code={`(http and request.method == "PUT") or (amqp and request.queue.startsWith("test"))\n or (kafka and response.payload.errorCode == 2) or (redis and request.key == "example")\n or (grpc and request.headers[":path"] == r".*foo.*")`}
                language="python"
              />
              <Typography id="modal-modal-description">
                By clicking the plus icon that appears beside the queryable UI elements on hovering in both left-pane and right-pane, you can automatically select a field and update the query:
              </Typography>
              <img
                src={filterUIExample1}
                width={600}
                alt="Clicking to UI elements (left-pane)"
                title="Clicking to UI elements (left-pane)"
              />
              <Typography id="modal-modal-description">
                Such that; clicking this icon in left-pane, would append the query below:
              </Typography>
              <SyntaxHighlighter
                showLineNumbers={false}
                code={`and dst.name == "carts" and dst.namespace == "sock-shop"`}
                language="python"
              />
              <Typography id="modal-modal-description">
                Another queriable UI element example, this time from the right-pane:
              </Typography>
              <img
                src={filterUIExample2}
                width={300}
                alt="Clicking to UI elements (right-pane)"
                title="Clicking to UI elements (right-pane)"
              />
              <Typography id="modal-modal-description">
                A query that compares one selector to another is also a valid query:
              </Typography>
              <SyntaxHighlighter
                showLineNumbers={false}
                code={`http and (request.query["x"] == response.headers["y"]\n or response.content.text.contains(request.query["x"]))`}
                language="python"
              />
            </Grid>
            <Divider className={styles.divider2} orientation="vertical" flexItem />
            <Grid item xs style={{ margin: "10px" }}>
              <Typography id="modal-modal-description">
                There are a few helper methods included the in the filter language* to help building queries more easily.
              </Typography>
              <br></br>
              <Typography id="modal-modal-description">
                true if the given selector&apos;s value starts with (similarly <code style={{ fontSize: "14px" }}>endsWith</code>, <code style={{ fontSize: "14px" }}>contains</code>) the string:
              </Typography>
              <SyntaxHighlighter
                showLineNumbers={false}
                code={`request.path.startsWith("something")`}
                language="python"
              />
              <Typography id="modal-modal-description">
                a field that contains a JSON encoded string can be filtered based a JSONPath:
              </Typography>
              <SyntaxHighlighter
                showLineNumbers={false}
                code={`response.content.text.json().some.path == "somevalue"`}
                language="python"
              />
              <Typography id="modal-modal-description">
                fields that contain sensitive information can be redacted:
              </Typography>
              <SyntaxHighlighter
                showLineNumbers={false}
                code={`and redact("request.path", "src.name")`}
                language="python"
              />
              <Typography id="modal-modal-description">
                returns the UNIX timestamp which is the equivalent of the time that&apos;s provided by the string. Invalid input evaluates to false:
              </Typography>
              <SyntaxHighlighter
                showLineNumbers={false}
                code={`timestamp >= datetime("10/19/2021, 6:29:02.593 PM")`}
                language="python"
              />
              <Typography id="modal-modal-description">
                limits the number of records that are streamed back as a result of a query. Always evaluates to true:
              </Typography>
              <SyntaxHighlighter
                showLineNumbers={false}
                code={`and limit(100)`}
                language="python"
              />
            </Grid>
          </Grid>
          <br></br>
          <Typography id="modal-modal-description" style={{ fontSize: 12, fontStyle: 'italic' }}>
            Please refer to <Link href="https://docs.kubeshark.co/en/filtering#kfl-syntax-reference" underline="hover" target="_blank"><b>KFL Syntax Reference</b></Link> for more information.&nbsp;
            <a className="kbc-button kbc-button-xxs">Ctrl</a> + <a className="kbc-button kbc-button-xxs">Enter</a> keyboard shortcut applies the filter.
          </Typography>
        </Box>
      </Fade>
    </Modal>
  </React.Fragment>
}
