import { atom } from "recoil";
import { QueryFilters } from '../../types/queryFilters'

const queryFiltersAtom = atom<QueryFilters>({
  key: 'queryFiltersAtom',
  default: {
    global: '',
    default: '',
    saml: '',
    fetched: false
  }
});

export default queryFiltersAtom;
