import React from 'react'
import variables from '../../../../variables.module.scss'
import { Fab } from '@mui/material'
import StopCircleRoundedIcon from '@mui/icons-material/StopCircleRounded'

interface DeactivateScriptButtonProps {
  darkModeEnabled: boolean
  onClick: () => void
}

export const DeactivateScriptButton: React.FC<DeactivateScriptButtonProps> = ({ darkModeEnabled, onClick }) => {
  return (
    <Fab
      variant='extended'
      color='warning'
      onClick={onClick}
      sx={{
        backgroundColor: darkModeEnabled
          ? variables.lightOrangeColor
          : variables.orangeColor
      }}
    >
      <StopCircleRoundedIcon
        htmlColor={
          darkModeEnabled
            ? variables.orangeColor
            : variables.mainBackgroundColor
        }
        sx={{ mr: '10px' }}
      />
      Deactivate
    </Fab>
  )
}
