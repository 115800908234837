import { useState, useEffect } from 'react'
import { SessionStorageKey } from '../consts'

const usePageRefreshObserver = (): boolean => {
  const [isRefreshed, setIsRefreshed] = useState(false)

  useEffect(() => {
    const handleRefreshDetection = () => {
      if (sessionStorage.getItem(SessionStorageKey.PageRefreshed)) {
        setIsRefreshed(true)
      } else {
        setIsRefreshed(false)
      }

      sessionStorage.setItem(SessionStorageKey.PageRefreshed, 'true')
    }

    handleRefreshDetection()

    return () => {
      sessionStorage.removeItem(SessionStorageKey.PageRefreshed)
    }
  }, [])

  return isRefreshed
}

export default usePageRefreshObserver
