import { useCallback, useEffect, useState } from 'react'
import trafficPlaybackAtom, { TRAFFIC_PLAYBACK_OK } from "../recoil/trafficPlayback/atom";
import { useRecoilState, useRecoilValue } from 'recoil'
import { useAuth } from "../components/UI/Auth/Auth";
import { HubBaseUrl } from "../consts";
import sizeCapturedAtom from "../recoil/sizeCaptured/atom";
import entriesAtom from '../recoil/entries'
import { useInterval } from '../helpers/interval'

interface TrafficStats {
  entriesCaptured: number
  sizeCaptured: string
  fetchPcapsTotalSize: () => void
}

const useTrafficStats = (): TrafficStats => {
  const entries = useRecoilValue(entriesAtom)
  const [entriesCaptured, setEntriesCaptured] = useState(0);
  const [sizeCaptured, setSizeCaptured] = useRecoilState(sizeCapturedAtom);

  useEffect(() => {
    setEntriesCaptured(entries.size)
  }, [entries.size])

  const trafficPlayback = useRecoilValue(trafficPlaybackAtom)

  const { shouldAuthenticate } = useAuth()

  const fetchPcapsTotalSize = () => {
    fetch(
      `${HubBaseUrl}/pcaps/total-size`,
      {
        method: 'GET',
        headers: {
          'X-Kubeshark-Capture': 'ignore',
        },
      },
    )
      .then(response => {
        return response.text();
      })
      .then(rawJson => {
        return rawJson ? JSON.parse(rawJson) : {}
      })
      .then(data => {
        setSizeCaptured(data.total)
      })
      .catch(err => {
        console.error(err);
      });
  }

  useEffect(() => {
    if (trafficPlayback !== TRAFFIC_PLAYBACK_OK) {
      return
    }

    if (shouldAuthenticate) {
      return
    }

    fetchPcapsTotalSize()
  }, [trafficPlayback, shouldAuthenticate])

  useInterval(useCallback(() => {
    if (trafficPlayback !== TRAFFIC_PLAYBACK_OK) {
      return
    }

    if (shouldAuthenticate) {
      return
    }

    fetchPcapsTotalSize()
  }, [trafficPlayback, shouldAuthenticate]), 60000, false)

  return {entriesCaptured, sizeCaptured, fetchPcapsTotalSize}
}

export default useTrafficStats
