import { Script } from '../../../modals/ScriptingModal/ScriptingTypes'
import { HubBaseUrl } from '../../../../consts'
import { toast } from 'react-toastify'

export const putUpdateScript = (
  scriptKey: number,
  title: string,
  code: string
) => {
  const script: Script = { title: title, code: code }

  return fetch(
    `${HubBaseUrl}/scripts/${scriptKey}`,
    {
      method: 'PUT',
      headers: {
        'Content-Type': 'application/json',
        'X-Kubeshark-Capture': 'ignore',
      },
      body: JSON.stringify(script)
    },
  )
    .then(response => response.ok ? response : response.text().then(err => Promise.reject(err)))
    .then(response => response.json())
    .catch(err => {
      if (err.length > 0) {
        console.error(err);
        toast.error(err.toString(), {
          theme: "colored"
        })
      }
    })
}
