import React, { useMemo } from 'react'
import { Box } from '@mui/material'

import Queryable from '../../UI/Queryable/Queryable'

import { CaptureInterface } from '../../EntryListItem/Entry'

import { CaptureBackendAfPacketIcon } from '../../UI/Icons/CaptureBackendAfPacketIcon'
import { CaptureBackendEbpfIcon } from '../../UI/Icons/CaptureBackendEbpfIcon'
import { CaptureBackendEbpfTlsIcon } from '../../UI/Icons/CaptureBackendEbpfTlsIcon'

import variables from '../../../variables.module.scss'
import { CaptureSourceEnvoyIcon } from '../../UI/Icons/CaptureSourceEnvoyIcon'

interface CaptureBackendProps {
  capture: CaptureInterface
}

const captureBackend = {
  LIBPCAP: 'libpcap',
  AF_PACKET: 'af_packet',
  PF_RING: 'pf_ring',
  EBPF: 'ebpf',
  EBPF_TLS: 'ebpf_tls'
}

export const CaptureBackend: React.FC<CaptureBackendProps> = ({ capture }) => {

  const backendIcon = useMemo(() => {
    switch(capture.backend) {
    case captureBackend.AF_PACKET:
      return <CaptureBackendAfPacketIcon />
    case captureBackend.EBPF:
      return <CaptureBackendEbpfIcon />
    case captureBackend.EBPF_TLS:
      return <CaptureBackendEbpfTlsIcon />
    default:
      return (
        <Box
          boxSizing='border-box'
          display='flex'
          alignItems='center'
          padding='1px 3px'
          borderRadius='4px'
          border={`1px solid ${variables.lighterGrayColor}`}
          bgcolor={variables.mainBackgroundColor}
        >
          <span style={{
            fontSize: '10px',
            fontWeight: '600',
            textTransform: 'uppercase',
            letterSpacing: '0.05em',
            color: variables.lightGrayColorAlt
          }}>
            {capture.backend}
          </span>
        </Box>
      )
    }
  }, [capture.backend])

  const sourceData = useMemo(() => {
    if (capture.source.match(/^netns-.*/) !== null) {
      return {
        icon: <CaptureSourceEnvoyIcon />,
        title: 'Envoy'
      }
    }
    return null
  }, [capture.source])

  return (
    <Box boxSizing='border-box' display='flex' alignItems='center' gap='5px' ml='5px' mr='10px'>
      <Box boxSizing='border-box' position='relative'>
        <Queryable
          query={`capture.backend == "${capture.backend}"`}
          displayIconOnMouseOver={true}
          flipped={true}
          style={{ whiteSpace: 'nowrap', overflow: 'visible' }}
          iconStyle={{
            transform: 'translate(-2px, -18px)',
          }}
          tooltipStyle={{
            transform: 'translate(18px, -17px)',
          }}
        >
          {backendIcon}
        </Queryable>
      </Box>
      {sourceData !== null && (
        <Box boxSizing='border-box' position='relative'>
          <Queryable
            query={`capture.source == "${capture.source}"`}
            queryNote={`${sourceData.title} capture source identified`}
            displayIconOnMouseOver={true}
            flipped={true}
            style={{ whiteSpace: 'nowrap', overflow: 'visible' }}
            iconStyle={{
              transform: 'translate(-2px, -18px)',
            }}
            tooltipStyle={{
              transform: 'translate(18px, -32px)',
            }}
          >
            {sourceData.icon}
          </Queryable>
        </Box>
      )}
    </Box>
  )
}
