import { appAuthEnabled } from '../../../../types/global'
import { checkSamlEnabled, extractJwtPayload } from './SamlAuth'

interface AuthzActionsPayload {
  canDownloadPCAP?: boolean
  canUseScripting?: boolean
  canUpdateTargetedPods?: boolean
  canStopTrafficCapturing?: boolean
  showAdminConsoleLink?: boolean
}

export const AUTHZ_ACTIONS = {
  CAN_DOWNLOAD_PCAP: 'canDownloadPCAP',
  CAN_USE_SCRIPTING: 'canUseScripting',
  CAN_UPDATE_TARGETED_PODS: 'canUpdateTargetedPods',
  CAN_STOP_TRAFFIC_CAPTURING: 'canStopTrafficCapturing',
  SHOW_ADMIN_CONSOLE_LINK: 'showAdminConsoleLink',
}

const getSamlAuthzActionsPayload = (): Array<AuthzActionsPayload> => {
  const jwtPayload = extractJwtPayload() ?? []
  return jwtPayload['authorizedActions'] as Array<AuthzActionsPayload>
}

export const authorizeAction = (action: string): boolean => {
  if (appAuthEnabled() && checkSamlEnabled()) {
    const authzActions = getSamlAuthzActionsPayload()
    if (authzActions === undefined || authzActions[action] === undefined) {
      return false
    }

    return authzActions[action]
  }

  return true
}
