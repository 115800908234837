import { useCallback } from 'react'
import { Entry } from '../EntryListItem/Entry'

import { useSetRecoilState } from 'recoil'

import { heapCacheObject, heapCacheString } from '../../helpers/cacher/cacher'
import cacheableEntryFields from '../TrafficEntry/cacheableEntryFields'

import { v4 as uuidv4 } from 'uuid'

import entriesAtom from '../../recoil/entries'
import { toast } from 'react-toastify'

export const useWebSocketEventHandlers = () => {
  const setEntries = useSetRecoilState(entriesAtom)

  const onMessage = useCallback((e) => {
    if (!e?.data) {
      return
    }

    let entry: Entry = JSON.parse(e.data)

    // TODO: Display the items with `event` field non-null
    //  in a new tab named `EVENT` instead of `API CALLS`
    if (entry.event) {
      return
    }

    const entryId = heapCacheString(entry.id)
    entry.key = `${entryId}-${uuidv4()}`

    entry = heapCacheObject(
      JSON.parse(e.data),
      cacheableEntryFields
    )

    setEntries((prevEntries) => {
      prevEntries.set(entryId, entry)
      return prevEntries
    })
  }, [])

  const onError = useCallback((err) => {
    console.error('WebSocket error:', err);
    if (err) {
      toast.error(err, {
        theme: 'colored',
        autoClose: 1000,
      })
    }
  }, [])

  return {
    onMessage,
    onError
  }
}
