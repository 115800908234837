import React from 'react'
import { Box } from '@mui/material'

import variables from '../../../variables.module.scss'
import RadioButtonCheckedIcon from '@mui/icons-material/RadioButtonChecked';
import Queryable from '../../UI/Queryable/Queryable'

interface RecordedBadgeProps {
  recordName: string
}

export const RecordedBadge: React.FC<RecordedBadgeProps> = ({ recordName }) => {
  if (recordName === '') {
    return null
  }

  return (
    <Queryable
      query={`record("${recordName}")`}
      displayIconOnMouseOver={true}
      flipped={true}
      style={{ whiteSpace: 'nowrap', overflow: 'visible' }}
      iconStyle={{
        transform: 'translate(-2px, -18px)',
      }}
      tooltipStyle={{
        transform: 'translate(18px, -17px)',
      }}
    >
      <Box
        boxSizing='border-box'
        padding='2px 4px'
        display='flex'
        alignItems='center'
        gap='2px'
        borderRadius='4px'
        border={`1px solid ${variables.lighterFailureColor}`}
        bgcolor={variables.lighterFailureColor}
      >
        <RadioButtonCheckedIcon
          htmlColor={variables.failureColor}
          sx={{
            fontSize: '12px'
          }}
        />
        <span
          style={{
            fontSize: '10px',
            fontWeight: 600,
            fontFamily: 'Roboto, sans-serif',
            lineHeight: 1,
            textTransform: 'uppercase',
            letterSpacing: '0.03em',
            color: variables.grayColor
          }}
        >
          REC
        </span>
      </Box>
    </Queryable>
  )
}
