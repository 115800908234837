export const defaultEnabledDissectors = {
  amqp: false,
  dns: false,
  http: false,
  icmp: false,
  kafka: false,
  redis: false,
  sctp: false,
  syscall: false,
  ws: false,
  tcp: false,
  udp: false,
  tls: false,
}
