import { useRecoilState, useRecoilValue, useSetRecoilState } from 'recoil'
import packetCapturingStoppedAtom from '../recoil/packetCapturingStopped'
import { HubBaseUrl } from '../consts'
import { toast } from 'react-toastify'
import { EnabledDissectors } from '../types/trafficSettings'
import enabledDissectorsAtom from '../recoil/enabledDissectors'
import trafficSettingsModalOpenAtom from '../recoil/trafficSettingsModalOpen'
import { useCallback } from 'react'
import { useInterval } from '../helpers/interval'
import trafficPlaybackAtom from '../recoil/trafficPlayback'
import { TRAFFIC_PLAYBACK_OK } from '../recoil/trafficPlayback/atom'
import { useAuth } from '../components/UI/Auth/Auth'
import {
  getCapturingStatus as fetchCapturingStatus,
  TrafficCapturingSettingsResponse
} from '../components/TrafficViewer/api/getCapturingStatus'

export const useTrafficCapturingStatus = () => {
  const trafficPlayback = useRecoilValue(trafficPlaybackAtom)

  const [capturingStopped, setCapturingStopped] = useRecoilState(packetCapturingStoppedAtom)
  const setTrafficSettingsModalOpen = useSetRecoilState(trafficSettingsModalOpenAtom)
  const [enabledDissectors, setEnabledDissectors] = useRecoilState<EnabledDissectors>(enabledDissectorsAtom)

  const { shouldAuthenticate } = useAuth()

  const getCapturingStatus = () => {
    fetchCapturingStatus().then((responseBody: TrafficCapturingSettingsResponse) => {
      const stopped = typeof responseBody.stopped === 'boolean' && responseBody.stopped ||
        typeof responseBody.stopped === 'string' && responseBody.stopped === 'true'

      setCapturingStopped(stopped)
      if (stopped) {
        setTrafficSettingsModalOpen(true)
      }

      const dissectors: EnabledDissectors = {}

      Object.keys(enabledDissectors).map(dissector => {
        dissectors[dissector] = responseBody.enabledDissectors.includes(dissector)
      })

      setEnabledDissectors(dissectors)
    })
  }

  const postCapturingStoppedStatus = (state: boolean) => {
    fetch(`${HubBaseUrl}/settings/stopped`, {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
        'X-Kubeshark-Capture': 'ignore',
      },
      body: JSON.stringify({ state })
    })
      .then((response) =>
        response.ok
          ? response
          : response.text().then((err) => Promise.reject(err))
      )
      .then((response) => response.json())
      .then((responseBody: TrafficCapturingSettingsResponse) => {
        setCapturingStopped(
          typeof responseBody.stopped === 'boolean' && responseBody.stopped ||
          typeof responseBody.stopped === 'string' && responseBody.stopped === 'true'
        )
      })
      .catch((err) => {
        if (err.length > 0) {
          console.error(err)
          toast.error(err.toString(), {
            theme: 'colored'
          })
        }
      })
  }

  useInterval(useCallback(() => {
    if (trafficPlayback !== TRAFFIC_PLAYBACK_OK) {
      return
    }

    if (shouldAuthenticate) {
      return
    }

    if (capturingStopped === false) {
      getCapturingStatus()
    }
  }, [trafficPlayback, shouldAuthenticate, capturingStopped]), 3000, false)

  return {
    getCapturingStatus,
    postCapturingStoppedStatus
  }
}
