export interface LicenseDoc {
  app_id: string
  initialCapacity: number
  capacity: number
  edition: string
  email: string
  end: number
  nodes: number
  userId: string
}

export interface LicenseInfo {
  currentNodeCount: number
  doc: LicenseDoc
  maxNodeCount: number
  valid: boolean
}

export enum LicenseApiCapacityOption {
  Limited = 0,
  Unlimited = 1,
  Unset = 2
}

export enum LicenseExpiryOption {
  Ends = 0,
  Endless = 1
}
