import React from 'react'

interface IconProps {
  stroke?: string
}

export const MinimizeIcon: React.FC<IconProps> = ({ stroke = 'black' }) => {
  return (
    <svg
      xmlns='http://www.w3.org/2000/svg'
      width='26'
      height='26'
      viewBox='0 0 24 24'
      fill='none'
      stroke={stroke}
      strokeWidth='2'
      strokeLinecap='round'
      strokeLinejoin='round'
    >
      <path d='M8 3v3a2 2 0 0 1-2 2H3' />
      <path d='M21 8h-3a2 2 0 0 1-2-2V3' />
      <path d='M3 16h3a2 2 0 0 1 2 2v3' />
      <path d='M16 21v-3a2 2 0 0 1 2-2h3' />
    </svg>
  )
}
