import React, {
  useImperativeHandle,
  forwardRef, useRef, useCallback
} from 'react'
import { FixedSizeList as List } from 'react-window';
import AutoSizer, { Size } from 'react-virtualized-auto-sizer';

export interface VirtualizedListHandle {
  jumpToBottom: () => void
}

export interface VirtualizedListProps<T> {
  renderItem: (item: T, index: number, style) => React.ReactNode
  itemHeight: number
  items: T[]
  isSnappedToBottom: boolean
  setIsSnappedToBottom: (state: boolean) => void
  onSnapBroken?: () => void
}

const useMutationObserver = (
  ref,
  callback,
  options: MutationObserverInit
) => {
  React.useEffect(() => {
    if (ref.current) {
      const observer = new MutationObserver(callback);
      observer.observe(ref.current, options);
      return () => observer.disconnect();
    }
  }, [callback, options]);
};

export const VirtualizedList = forwardRef(<T,>(props: VirtualizedListProps<T>, ref) => {
  const {
    items,
    itemHeight,
    renderItem,
    isSnappedToBottom,
    setIsSnappedToBottom,
    onSnapBroken
  } = props;

  const listRef = useRef(null)
  const listOuterRef = useRef(null)

  useImperativeHandle(ref, () => ({
    jumpToBottom: () => {
      if (listOuterRef.current) {
        listRef.current.scrollToItem(items.length);
        setIsSnappedToBottom(true)
      }
    }
  }));

  const callback = useCallback((mutationsList) => {
    if (!isSnappedToBottom) {
      return
    }

    for (const mutation of mutationsList) {
      if (mutation.type === 'childList') {
        listRef.current.scrollToItem(items.length);
      }
    }
  }, [items.length, isSnappedToBottom])

  useMutationObserver(listOuterRef, callback, { childList: true, subtree: true })

  return (
    <div style={{ display: 'flex', flexGrow: 1 }} onWheel={() => onSnapBroken()}>
      <AutoSizer disableWidth={false}>
        {({ height, width }: Size) => {
          return (
            <List
              ref={listRef}
              outerRef={listOuterRef}
              height={height}
              width={width}
              itemCount={items.length}
              itemSize={itemHeight}
              itemData={items}
            >
              {({ index, style }) => (
                renderItem(items[index], index, style)
              )}
            </List>
          )
        }}
      </AutoSizer>
    </div>
  );
});
