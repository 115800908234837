import { atom } from "recoil";
import { V1Pod } from '@kubernetes/client-node'


const targetedPodsAtom = atom({
  key: "targetedPodsAtom",
  default: [] as V1Pod[]
});

export default targetedPodsAtom;
