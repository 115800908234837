import React from 'react'
import variables from '../../../../variables.module.scss'
import PlayArrowRoundedIcon from '@mui/icons-material/PlayArrowRounded'
import { Fab } from '@mui/material'

interface ActivateScriptButtonProps {
  darkModeEnabled: boolean
  onClick: () => void
}

export const ActivateScriptButton: React.FC<ActivateScriptButtonProps> = ({ darkModeEnabled, onClick }) => {
  return (
    <Fab
      variant='extended'
      color='success'
      onClick={onClick}
      sx={{
        backgroundColor: darkModeEnabled
          ? variables.lightSuccessColor
          : variables.successColor
      }}
    >
      <PlayArrowRoundedIcon
        htmlColor={
          darkModeEnabled
            ? variables.successColor
            : variables.mainBackgroundColor
        }
        sx={{ mr: '10px' }}
      />
      Activate
    </Fab>
  )
}
