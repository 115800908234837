import React, { useEffect, useState } from 'react'

import variables from '../../../../variables.module.scss'
import { Box, Button, Tooltip, Typography } from '@mui/material'
import { getAuthSessionDetails } from '../../../UI/Auth/AuthSessionDetails'
import RotateLeftRoundedIcon from '@mui/icons-material/RotateLeftRounded'
import { UnsetLicenseModal } from '../../UnsetLicenseModal/UnsetLicenseModal'
import useLicense from '../../../../hooks/useLicense'
import trafficPlaybackAtom, {
  TRAFFIC_PLAYBACK_LOCKED,
  TRAFFIC_PLAYBACK_OK
} from '../../../../recoil/trafficPlayback/atom'
import { useRecoilValue, useSetRecoilState } from 'recoil'
import authSessionOkAtom from '../../../../recoil/authSessionOk/atom'

interface LicenseeInfoProps {
  closeLicenseInfoModal: () => void
}

export const LicenseeInfo: React.FC<LicenseeInfoProps> = ({
  closeLicenseInfoModal
}) => {
  const { licenseInfo: info, commercialEdition } = useLicense()

  const authSessionOk = useRecoilValue(authSessionOkAtom)
  const [userIsLicensee, setUserIsLicensee] = useState(false)

  useEffect(() => {
    setUserIsLicensee(getAuthSessionDetails()?.userId === info.doc.userId)
  }, [info, authSessionOk])

  const setTrafficPlayback = useSetRecoilState(trafficPlaybackAtom)
  const [unsetLicenseModalOpen, openUnsetLicenseModal] = useState(false)

  const handleRemoveLicense = () => {
    setTrafficPlayback(TRAFFIC_PLAYBACK_LOCKED)
    openUnsetLicenseModal(true)
  }

  const handleLicenseRemoveConfirmed = () => {
    setTrafficPlayback(TRAFFIC_PLAYBACK_OK)
    closeLicenseInfoModal()
  }

  const handleLicenseRemoveCancelled = () => {
    setTrafficPlayback(TRAFFIC_PLAYBACK_OK)
  }

  return (
    <>
      {commercialEdition && (
        <Box width='100%' borderRadius='4px' boxSizing='border-box'>
          <Typography
            variant='body1'
            fontFamily={variables.textFontFamily}
            fontWeight={500}
            color={variables.fontColor}
          >
            License:{' '}
            <strong style={{ color: variables.blueColor }}>
              {!commercialEdition
                ? 'NOT DETECTED'
                : info.doc.edition.toUpperCase()}
            </strong>
          </Typography>
          <Typography
            variant='body1'
            fontFamily={variables.textFontFamily}
            fontWeight={500}
            color={variables.fontColor}
          >
            Licensee: {userIsLicensee && 'You, '}{' '}
            <span style={{ fontWeight: 600 }}>{info.doc.email}</span>
          </Typography>
        </Box>
      )}
      {commercialEdition && userIsLicensee && (
        <>
          <Tooltip
            arrow
            placement='top'
            title={
              !userIsLicensee
                ? 'Only licensee can remove this license'
                : 'Remove current license'
            }
          >
            <Button
              className={`themeButton secondary outlined ${
                !userIsLicensee ? 'disabled' : ''
              }`}
              // color='error'
              variant='contained'
              size='medium'
              startIcon={<RotateLeftRoundedIcon sx={{ marginRight: '5px' }} />}
              sx={{
                bgcolor: variables.failureColor,
                mt: '10px',
                cursor: userIsLicensee ? 'pointer' : 'auto'
              }}
              onClick={userIsLicensee ? handleRemoveLicense : null}
              fullWidth
            >
              <Typography
                variant='body1'
                fontSize={14}
                fontFamily={variables.textFontFamily}
                fontWeight={600}
              >
                Remove License
              </Typography>
            </Button>
          </Tooltip>
          <UnsetLicenseModal
            open={unsetLicenseModalOpen}
            setOpen={openUnsetLicenseModal}
            handleConfirmed={handleLicenseRemoveConfirmed}
            handleCancelled={handleLicenseRemoveCancelled}
          />
        </>
      )}
    </>
  )
}
