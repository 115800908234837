import React, { useCallback, useEffect, useRef, useState } from 'react'

import { EnabledDissectors } from '../../../../../../types/trafficSettings'

import { Box, Button, CircularProgress, FormControlLabel, Grid, Grow, Tooltip, Typography } from '@mui/material'
import { StyledSwitch } from '../../../../../UI/StyledSwitch/StyledSwitch'

import { HubBaseUrl } from '../../../../../../consts'
import variables from '../../../../../../variables.module.scss'
import { toast } from 'react-toastify'
import {
  getCapturingStatus as fetchCapturingStatus,
  TrafficCapturingSettingsResponse
} from '../../../../../TrafficViewer/api/getCapturingStatus'
import { defaultEnabledDissectors } from '../../../../../../recoil/enabledDissectors/default'

import CircleIcon from '@mui/icons-material/Circle';
import enabledDissectorsAtom from '../../../../../../recoil/enabledDissectors'
import { useRecoilValue } from 'recoil'

import { diff } from 'deep-object-diff'

interface DissectorSwitchProps {
  name: string
  isChecked: boolean
  disabled: boolean
  reset: boolean
  onChange: (checked: boolean) => void
}

const DissectorSwitch: React.FC<DissectorSwitchProps> = ({ name, isChecked = false, disabled, reset, onChange }) => {
  const [checked, setChecked] = useState(isChecked)
  const [initialChecked, setInitialChecked] = useState(null)

  useEffect(() => setChecked(isChecked), [isChecked])

  useEffect(() => setInitialChecked(null), [reset])

  const handleChange = (event) => {
    if (initialChecked === null) {
      setInitialChecked(!event.target.checked)
    }

    setChecked(event.target.checked)
    onChange(event.target.checked)
  }

  return (
    <FormControlLabel
      disabled={disabled}
      control={
        <StyledSwitch
          checked={checked}
          onChange={handleChange}
        />
      }
      labelPlacement="start"
      label={
        <Typography
          variant="body2"
          fontFamily={variables.textFontFamily}
          fontWeight={600}
          textAlign="left"
          lineHeight={1}
          color={variables.fontColor}
          width="100%"
        >
          {initialChecked !== null && initialChecked !== checked && (
            <CircleIcon
              htmlColor={variables.darkerWarningColor}
              sx={{ fontSize: '10px', mr: '10px' }}
            />
          )}
          {name.toUpperCase()}
        </Typography>
      }
      sx={{ width: '100%' }}
    />
  )
}

export const EnabledDissectorsView: React.FC = () => {
  const dissectorsGridRef = useRef(null)
  const applyBtnContainerRef = useRef(null)

  const enabledDissectors = useRecoilValue(enabledDissectorsAtom)
  const [dissectors, setDissectors] = useState<EnabledDissectors>(defaultEnabledDissectors)
  const [dissectorsFetched, setDissectorsFetched] = useState(false)
  const [dissectorsChangedSignal, setDissectorsChangedSignal] = useState(null)
  const [dissectorsApplying, setDissectorsApplying] = useState(false)
  const [dissectorsEntries, setDissectorsEntries] = useState([])
  const [gridHeight, setGridHeight] = useState(null)

  useEffect(() => loadDissectorsIntoView(), [])

  useEffect(() => {
    if (!dissectorsFetched) {
      return
    }

    setDissectorsEntries(Object.entries(dissectors))

    const dissectorsChanged = Object.keys(diff(dissectors, enabledDissectors)).length > 0
    setDissectorsChangedSignal(dissectorsChanged ? Date.now() : null)
  }, [dissectors, dissectorsFetched])

  useEffect(() => {
    if (dissectorsChangedSignal !== null && applyBtnContainerRef.current) {
      setTimeout(() => {
        applyBtnContainerRef.current.scrollIntoView({
          behavior: 'smooth',
          block: 'end'
        })
      }, 150)
    }
  }, [dissectorsChangedSignal])

  const loadDissectorsIntoView = () => {
    setDissectorsFetched(false)

    fetchCapturingStatus().then((responseBody: TrafficCapturingSettingsResponse) => {
      const dissectors: EnabledDissectors = {}

      Object.keys(defaultEnabledDissectors).map(dissector => {
        dissectors[dissector] = responseBody.enabledDissectors.includes(dissector)
      })

      setDissectors(dissectors)
    }).then(() => setDissectorsFetched(true))
  }

  const postEnabledDissectors = (enabledDissectors: string[]) => {
    fetch(
      `${HubBaseUrl}/settings/enabled-dissectors`,
      {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
          'X-Kubeshark-Capture': 'ignore'
        },
        body: JSON.stringify({ enabledDissectors })
      }
    )
      .then((response) =>
        response.ok
          ? response
          : response.text().then((err) => Promise.reject(err))
      )
      .catch((err) => {
        console.error(err)
        toast.error(`Failed to set enabled dissectors`, {
          theme: 'colored'
        })
      }).finally(() => {
        setDissectorsApplying(false)
        setDissectorsChangedSignal(null)
      })
  }

  const handleApplyEnabledDissectors = useCallback(() => {
    if (dissectorsApplying) {
      return
    }

    setDissectorsApplying(true)

    const enabledDissectors = Object.keys(dissectors).filter((dissector) => {
      if (dissectors[dissector]) {
        return dissector
      }
    })

    postEnabledDissectors(enabledDissectors)
  }, [dissectors, dissectorsApplying])

  const handleCancelDissectorsChange = () => {
    loadDissectorsIntoView()
    setDissectorsApplying(false)
    setDissectorsChangedSignal(null)
  }

  return (
    <Box
      boxSizing='border-box'
      width='100%'
      height='100%'
      display='flex'
      alignItems='center'
      flexDirection='column'
    >
      <Typography
        variant='body1'
        fontFamily={variables.textFontFamily}
        fontWeight={500}
        fontSize='16px'
        color={variables.secondaryFontColor}
        mb='20px'
      >
        TCP, UDP and DNS protocol dissectors can consume significant CPU and memory resources. We suggest enabling them only on demand.
      </Typography>
      <Grid ref={dissectorsGridRef} container spacing={{ xs: 1, md: 2 }} columns={{ xs: 4, sm: 8, md: 12 }}>
        {!dissectorsFetched && (
          <Grid item xs={12} sm={12} md={12} key='loading-dissectors'>
            <Box
              boxSizing='border-box'
              width='100%'
              height={gridHeight !== null ? `${gridHeight}px` : '100%'}
              padding='16px'
              display='flex'
              alignItems='center'
              justifyContent='center'
              gap='10px'
              borderRadius='4px'
              border={`1px solid ${variables.lighterGrayColor}`}
              bgcolor={variables.lightGrayBlueColor}
            >
              <CircularProgress size={14} sx={{
                color: variables.blueColor
              }} />
              <Typography
                variant='body1'
                fontFamily='Roboto, sans-serif'
                fontWeight={500}
                fontSize='14px'
                color={variables.grayColor}
              >
                Loading dissectors
              </Typography>
            </Box>
          </Grid>
        )}
        {dissectorsFetched && dissectorsEntries.map(([dissector, state]) => {
          return (
            <Grid item xs={4} sm={4} md={4} key={dissector}>
              <Box
                p="8px"
                pr="24px"
                borderRadius="6px"
                border={`1px solid ${variables.lightestSlateColor}`}
                bgcolor={variables.dataBackgroundColor}
              >
                <DissectorSwitch
                  name={dissector}
                  isChecked={state}
                  disabled={dissectorsApplying}
                  reset={!dissectorsApplying}
                  onChange={(checked) => {
                    if (dissectorsGridRef.current !== null) {
                      setGridHeight(dissectorsGridRef.current.clientHeight)
                    }

                    setDissectors(prevState => {
                      const newState = { ...prevState }
                      newState[dissector] = checked
                      return newState
                    })
                  }}
                />
              </Box>
            </Grid>
          )
        })}
      </Grid>
      {dissectorsChangedSignal !== null && enabledDissectors && (
        <Grow in>
          <Box
            ref={applyBtnContainerRef}
            boxSizing='border-box'
            width='100%'
            mt='20px'
            pb='20px'
          >
            <Tooltip title='Apply new dissectors' placement='top' arrow>
              <Button
                variant='contained'
                size='large'
                color='success'
                fullWidth
                disableElevation
                startIcon={dissectorsApplying ? <CircularProgress size={14} color='inherit' sx={{ mr: '10px' }} /> : null}
                onClick={handleApplyEnabledDissectors}
                sx={{
                  backgroundColor: variables.successColor,
                  pointerEvents: dissectorsApplying ? 'none' : 'auto',
                }}
              >
                <Typography
                  variant='body2'
                  fontFamily={variables.textFontFamily}
                  fontWeight={600}
                  textTransform='none'
                  lineHeight={1}
                >
                  {dissectorsApplying ? 'Applying' : 'Apply changes'}
                </Typography>
              </Button>
            </Tooltip>
            {!dissectorsApplying && <Button
              variant='outlined'
              size='large'
              color='info'
              fullWidth
              disableElevation
              onClick={handleCancelDissectorsChange}
              sx={{
                mt: '10px',
              }}
            >
              <Typography
                variant='body2'
                fontFamily={variables.textFontFamily}
                fontWeight={600}
                textTransform='none'
                lineHeight={1}
              >
                Cancel
              </Typography>
            </Button>}
          </Box>
        </Grow>
      )}
    </Box>
  )
}
