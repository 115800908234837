import { HubBaseUrl } from '../../../../consts'
import { toast } from 'react-toastify'

export const deleteScript = (scriptKey: number) => {
  return fetch(
    `${HubBaseUrl}/scripts/${scriptKey}`,
    {
      method: 'DELETE',
      headers: {
        'X-Kubeshark-Capture': 'ignore',
      },
    },
  )
    .then(response => response.ok ? response : response.text().then(err => Promise.reject(err)))
    .catch(err => {
      if (err.length > 0) {
        console.error(err)
        toast.error(err.toString(), {
          theme: "colored"
        })
      }
    })
}
