import React from 'react'

import { ServiceMap } from '../../modals/ServiceMapModal/ServiceMapModal'

import styles from './ServiceMapView.module.sass'

export const ServiceMapView: React.FC = () => {
  return (
    <div className={styles.ServiceMapViewContainer}>
      <ServiceMap />
    </div>
  )
}
