import { useCallback, useEffect } from 'react'
import { useSearchParams } from 'react-router-dom'
import trafficSettingsModalOpenAtom from '../recoil/trafficSettingsModalOpen'
import { useRecoilState } from 'recoil'
import { appAuthEnabled, appAuthType } from '../types/global'
import { OidcAuthType } from '../consts'

const useElementRouter = () => {
  const [modalOpen, setModalOpen] = useRecoilState(trafficSettingsModalOpenAtom)

  const [searchParams, setSearchParams] = useSearchParams()

  useEffect(() => {
    if (searchParams.toString().includes('opensettings=targeting')) {
      setModalOpen(true)
      setModalOpen({ open: true, settingId: 'capture-filters' })
    } else if (searchParams.toString().includes('opensettings=dissectors')) {
      setModalOpen(true)
      setModalOpen({ open: true, settingId: 'enabled-dissectors' })
    } else if (searchParams.toString().includes('opensettings=query-filters')) {
      setModalOpen(true)
      setModalOpen({ open: true, settingId: 'query-filters' })
    } else if (searchParams.toString().includes('opensettings')) {
      setModalOpen(true)
      setModalOpen({ open: true, settingId: 'root' })
    } else {
      if (modalOpen) {
        setModalOpen({ open: true, settingId: 'root' })
      }
    }
  }, [searchParams])

  const navigateToElement = useCallback((id: string) => {
    if (appAuthEnabled() && appAuthType() === OidcAuthType) {
      searchParams.delete('code')
      searchParams.delete('descope-login-flow')
    }

    switch(id) {
    case 'settings:targeting':
      searchParams.set('opensettings', 'targeting')
      break
    case 'settings:query-filters':
      searchParams.set('opensettings', 'query-filters')
      break
    case 'settings:dissectors':
      searchParams.set('opensettings', 'dissectors')
      break
    case 'settings':
      searchParams.set('opensettings', 'root')
      break
    case '':
      searchParams.delete('opensettings')
      break
    }

    setSearchParams(searchParams, { replace: true })
  }, [searchParams])

  return { navigateToElement }
}

export default useElementRouter
