import React from 'react'
import variables from '../../../../variables.module.scss'
import '../../TrafficEntry.module.sass'
import styles from './UnresolvedFieldBadge.module.sass'
import { Box } from '@mui/material'

interface UnresolvedFieldBadgeProps {
  text?: string
}

export const UnresolvedFieldBadge: React.FC<UnresolvedFieldBadgeProps> = ({ text = 'unresolved' }) => {
  return (
    <Box className={styles.UnresolvedFieldBadge}>
      <span className='DataText' style={{ color: variables.lightGrayColor }}>
        {text}
      </span>
    </Box>
  )
}
