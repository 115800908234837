import React from 'react'

import variables from '../../../variables.module.scss'

interface InlineCodeProps {
  children: string,
  color?: string
}

export const InlineCode: React.FC<InlineCodeProps> = ({ children, color = 'inherit' }) => {
  return (
    <code
      style={{
        background: variables.headerBackgroundColor,
        borderRadius: '4px',
        fontSize: '12px',
        padding: '2px 4px',
        border: `1px solid ${variables.dataBackgroundColor}`,
        verticalAlign: 'middle',
        color: color
      }}
    >
      {children}
    </code>
  )
}
