import React from 'react'
import Queryable from '../../UI/Queryable/Queryable'

import { LockIcon } from '../../EntryListItem/icons/LockIcon'

import '../TrafficEntry.module.sass'
import { Box } from '@mui/material'

interface TlsIndicatorProps {
  tls: boolean
}

export const TlsIndicator: React.FC<TlsIndicatorProps> = ({ tls }) => {
  if (!tls) {
    return <></>
  }

  return (
    <Box position='relative' mr='5px'>
      <div className='padlock'>
        <Queryable
          query={`tls == "${tls}"`}
          displayIconOnMouseOver={true}
          flipped={false}
          style={{ position: 'absolute' }}
        >
          <LockIcon />
        </Queryable>
      </div>
    </Box>
  )
}
