import React from 'react'
import variables from '../../../../variables.module.scss'
import { Fab } from '@mui/material'
import TerminalRoundedIcon from '@mui/icons-material/TerminalRounded'
import Tooltip from '@mui/material/Tooltip'

interface ScriptLogsButtonProps {
  darkModeEnabled: boolean
  scriptActive: boolean
  onClick: () => void
}

export const ScriptLogsButton: React.FC<ScriptLogsButtonProps> = ({ darkModeEnabled, scriptActive, onClick }) => {
  return (
    <Tooltip
      title={
        !scriptActive ?
          'Script is inactive & stopped. Activate it, first!' : null
      }
      placement='top-end'
      arrow>
      <Fab
        variant='extended'
        color='default'
        onClick={scriptActive ? onClick : null}
        sx={{
          backgroundColor: darkModeEnabled
            ? variables.githubEditorBackgroundColorLight
            : variables.slateColor,
          color: darkModeEnabled
            ? variables.lighterGrayColor
            : variables.mainBackgroundColor,
          '&:hover': {
            backgroundColor: darkModeEnabled
              ? variables.githubEditorBackgroundColorLight
              : variables.slateColor
          },
          opacity: scriptActive ? 1 : 0.7,
          cursor: scriptActive ? 'pointer' : 'not-allowed'
        }}
      >
        <TerminalRoundedIcon
          htmlColor={
            darkModeEnabled
              ? variables.lighterGrayColor
              : variables.mainBackgroundColor
          }
          sx={{ mr: '10px' }}
        />
        Script Logs
      </Fab>
    </Tooltip>

  )
}
