import React, { useEffect, useState } from 'react'
import { Box, IconButton, Tooltip, Typography } from '@mui/material'
import { SearchSplashIcon } from '../../UI/Icons/SearchSplashIcon'
import variables from '../../../variables.module.scss'
import CheckCircleRoundedIcon from '@mui/icons-material/CheckCircleRounded'
import { Entry } from '../../EntryListItem/Entry'
import SyntaxHighlighter from '../../UI/SyntaxHighlighter'
import { formatRequest } from '../EntrySections/EntrySections'
import ContentCopyRoundedIcon from '@mui/icons-material/ContentCopyRounded'
import CheckRoundedIcon from '@mui/icons-material/CheckRounded';

interface DetailsNotFoundPanelProps {
  entry: Entry
  state: boolean
  reason?: string
  solution?: string | JSX.Element
}

export const DetailsNotFoundPanel: React.FC<DetailsNotFoundPanelProps> = ({ entry, state, reason, solution }) => {
  const [entryCopied, setEntryCopied] = useState(false)
  useEffect(() => {
    if (entryCopied) {
      setTimeout(() => setEntryCopied(false), 2000)
    }
  }, [entryCopied])

  return state && (
    <Box width='100%' height='100%' display='flex' alignItems='center' flexDirection='column' gap='35px' justifyContent='flex-start'>
      <Box display='flex' alignItems='center' flexDirection='column' gap='20px' mt='25px'>
        <SearchSplashIcon stroke={variables.lightBlueColor} />
        <Typography variant='h1' fontSize={32} fontWeight={600} color={variables.fontColor} fontFamily={variables.textFontFamily}>
          Not found
        </Typography>
      </Box>
      {entry !== null && entry !== undefined && <Box
        position='relative'
        width='100%'
        borderRadius='4px'
        bgcolor={variables.mainBackgroundColor}
        border={`1px solid ${variables.lighterGrayColor}`}
      >
        <Box
          position='absolute'
          top='0'
          left='50%'
          display='flex'
          alignItems='center'
          padding='4px'
          bgcolor={variables.lightGrayColor}
          sx={{
            transform: 'translateX(-50%)',
            borderBottomLeftRadius: '4px',
            borderBottomRightRadius: '4px',
          }}
        >
          <span style={{
            fontSize: '10px',
            fontFamily: variables.textFontFamily,
            fontWeight: 600,
            textTransform: 'uppercase',
            color: '#ffffff'
          }}>
            Basic entry info
          </span>
        </Box>
        <Tooltip arrow placement='left' title={entryCopied ? 'Copied!' : 'Click to copy basic info'}>
          <IconButton
            color={entryCopied ? 'success' : 'info'}
            onClick={() => {
              navigator.clipboard.writeText(JSON.stringify(entry)).then(
                () => setEntryCopied(true)
              )
            }}
            sx={{
              position: 'absolute',
              top: '5px',
              right: '5px',
              borderRadius: '4px',
            }}
          >
            {entryCopied ?
              <CheckRoundedIcon fontSize='small' sx={{ background: '#ffffff' }} /> :
              <ContentCopyRoundedIcon fontSize='small' sx={{ background: '#ffffff' }} />
            }
          </IconButton>
        </Tooltip>
        <Box
          maxHeight='150px'
          overflow='auto'
        >
          <SyntaxHighlighter
            showLineNumbers
            code={formatRequest(
              { body: JSON.stringify(entry) },
              'application/yaml',
              true,
              false,
              true
            )}
            language="python"
            bgWhite
          />
        </Box>
      </Box>}
      <Box width='100%' display='flex' alignItems='center' flexDirection='column' gap='15px' justifyContent='center'>
        <Typography variant='body1' fontSize={18} color={variables.secondaryFontColor} fontFamily={variables.textFontFamily} align='center'>
          We could not find details for the selected entry. Try focusing on another one!
        </Typography>
        {reason && (
          <Box
            display='flex'
            alignItems='center'
            gap='5px'
            padding='8px'
            borderRadius='4px'
          >
            <Typography variant='body1' color={variables.fontColor} fontFamily={variables.textFontFamily}>
              <span style={{ color: variables.fontColor, fontWeight: 600 }}>Reason:</span> {reason}
            </Typography>
          </Box>
        )}
      </Box>
      {solution && (
        <Box
          display='flex'
          alignItems='center'
          gap='5px'
          padding='8px'
          border={`1px solid ${variables.successColor}`}
          borderRadius='4px'
          bgcolor={variables.lightSuccessColor}
        >
          <CheckCircleRoundedIcon htmlColor={variables.successColor} sx={{ fontSize: '20px' }} />
          <Typography variant='body1' color={variables.fontColor} fontFamily={variables.textFontFamily}>
            <span style={{ color: variables.fontColor, fontWeight: 600 }}>Solution:</span>
          </Typography>
          {solution}
        </Box>
      )}
    </Box>
  )
}
