import React from 'react'

import { getAuthSessionDetails } from '../Auth/AuthSessionDetails'

import authSessionOkAtom from '../../../recoil/authSessionOk/atom'
import { useRecoilValue } from 'recoil'

import variables from '../../../variables.module.scss'
import { Box, Typography } from '@mui/material'

export const LoggedInAs: React.FC = () => {
  const authSessionOk = useRecoilValue(authSessionOkAtom)

  return (
    authSessionOk && (
      <Box
        boxSizing='border-box'
        padding='2px 8px'
        borderRadius='6px'
        border={`1px solid ${variables.lightBlueColor}`}
        bgcolor={variables.dataBackgroundColor}
      >
        <Typography
          variant='body1'
          fontSize={13}
          fontFamily='Roboto'
          fontWeight={500}
          color={variables.fontColor}
        >
          {getAuthSessionDetails()?.email && (
            <>
              <span>Logged in as</span>&nbsp;
              <span style={{ fontWeight: 600, color: variables.blueColor }}>
                {getAuthSessionDetails().email}
              </span>
            </>
          )}
          {getAuthSessionDetails()?.email.length === 0 && (
            <span>You are logged in</span>
          )}
        </Typography>
      </Box>
    )
  )
}
