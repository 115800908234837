import React from 'react'

import variables from '../../../variables.module.scss'

interface IconProps {
  color?: string
}

export const CaptureBackendAfPacketIcon: React.FC<IconProps> = ({ color = variables.lightGrayColorAlt }) => {
  return (
    <svg width="20" height="20" viewBox="0 0 30 30" fill="none" xmlns="http://www.w3.org/2000/svg">
      <g opacity="1">
        <path
          d="M24.9013 10.7178V20.4726C24.9013 20.5851 24.8713 20.6955 24.8142 20.7924C24.7572 20.8893 24.6753 20.9691 24.5769 21.0237L15.7605 25.921C15.667 25.9728 15.5619 26 15.455 26C15.3482 26 15.243 25.9728 15.1496 25.921L6.33311 21.0237C6.23478 20.9691 6.15285 20.8893 6.09582 20.7924C6.03878 20.6955 6.00873 20.5851 6.00879 20.4726V10.7178C6.00892 10.6056 6.03906 10.4954 6.09608 10.3987C6.1531 10.302 6.23494 10.2223 6.33311 10.1679L15.1496 5.26945C15.243 5.21762 15.3482 5.19043 15.455 5.19043C15.5619 5.19043 15.667 5.21762 15.7605 5.26945L24.5769 10.1679C24.6751 10.2223 24.7569 10.302 24.814 10.3987C24.871 10.4954 24.9011 10.6056 24.9013 10.7178Z"
          stroke={color} strokeWidth="1.57437" strokeLinecap="round" strokeLinejoin="round" />
        <path
          d="M6.5625 10.656L15.1481 15.4253C15.2417 15.4773 15.347 15.5046 15.454 15.5046C15.5611 15.5046 15.6664 15.4773 15.76 15.4253L24.376 10.6392M15.4546 25.0415V15.5953"
          stroke={color} strokeWidth="1.57437" strokeLinecap="round" strokeLinejoin="round" />
        <path
          d="M6.00834 23.7293C6.77385 23.7293 7.508 23.4252 8.0493 22.8839C8.5906 22.3426 8.89469 21.6084 8.89469 20.8429C8.89469 20.0774 8.5906 19.3433 8.0493 18.802C7.508 18.2607 6.77385 17.9566 6.00834 17.9566C5.24284 17.9566 4.50868 18.2607 3.96739 18.802C3.42609 19.3433 3.12199 20.0774 3.12199 20.8429C3.12199 21.6084 3.42609 22.3426 3.96739 22.8839C4.50868 23.4252 5.24284 23.7293 6.00834 23.7293Z"
          fill={color} stroke="white" strokeWidth="1.57437" strokeLinecap="round" strokeLinejoin="round" />
        <path
          d="M15.4546 7.98551C16.2201 7.98551 16.9543 7.68142 17.4956 7.14012C18.0369 6.59883 18.341 5.86467 18.341 5.09916C18.341 4.33366 18.0369 3.5995 17.4956 3.0582C16.9543 2.51691 16.2201 2.21281 15.4546 2.21281C14.6891 2.21281 13.955 2.51691 13.4137 3.0582C12.8724 3.5995 12.5683 4.33366 12.5683 5.09916C12.5683 5.86467 12.8724 6.59883 13.4137 7.14012C13.955 7.68142 14.6891 7.98551 15.4546 7.98551Z"
          fill={color} stroke="white" strokeWidth="1.57437" strokeLinecap="round" strokeLinejoin="round" />
        <path
          d="M24.9009 23.7293C25.6664 23.7293 26.4006 23.4252 26.9419 22.8839C27.4832 22.3426 27.7873 21.6084 27.7873 20.8429C27.7873 20.0774 27.4832 19.3433 26.9419 18.802C26.4006 18.2607 25.6664 17.9566 24.9009 17.9566C24.1354 17.9566 23.4013 18.2607 22.86 18.802C22.3187 19.3433 22.0146 20.0774 22.0146 20.8429C22.0146 21.6084 22.3187 22.3426 22.86 22.8839C23.4013 23.4252 24.1354 23.7293 24.9009 23.7293Z"
          fill={color} stroke="white" strokeWidth="1.57437" strokeLinecap="round" strokeLinejoin="round" />
      </g>
    </svg>
  )
}
