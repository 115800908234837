import React from 'react'
import { OidcAuth } from './OidcAuth/OidcAuth'
import { SamlAuth } from './SamlAuth/SamlAuth'
import { OidcAuthType, SamlAuthType } from '../../../consts'
import { appAuthEnabled, appAuthType } from '../../../types/global'
import { useRecoilValue } from 'recoil'
import shouldAuthenticateAtom from '../../../recoil/shouldAuthenticate/atom'

export const Auth: React.FC = () => {
  if (!appAuthEnabled()) {
    return <></>
  }

  const authType = appAuthType()

  switch (authType) {
  case OidcAuthType:
    return <OidcAuth />
  case SamlAuthType:
    return <SamlAuth />
  default:
    console.error('Unsupported auth type:', authType)
    return <></>
  }
}

interface AuthInterface {
  shouldAuthenticate: boolean
}

export const useAuth = (): AuthInterface => {
  const shouldAuthenticate = useRecoilValue(shouldAuthenticateAtom)

  return { shouldAuthenticate }
}
