import React from 'react'
import Queryable from '../../UI/Queryable/Queryable'
import { Utils } from '../../../helpers/Utils'

interface RouteProps {
  name: string
  truncateName?: boolean
  query: string
}

export const Route: React.FC<RouteProps> = ({ name, truncateName = false, query }) => {
  if (!name) {
    return <></>
  }

  return (
    <Queryable
      query={query}
      displayIconOnMouseOver={true}
      flipped={true}
      iconStyle={{ zIndex: '5', position: 'relative', right: '14px' }}
      tooltipStyle={{ marginLeft: '10px' }}
    >
      <span style={{ fontWeight: 600, whiteSpace: 'nowrap' }}>
        {truncateName ? Utils.truncateString(name, 25, 20) : name}
      </span>
    </Queryable>
  )
}
