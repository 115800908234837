import React, { useEffect, useRef, useState } from 'react'

import { Box, Button, ButtonBase, IconButton, TextField, Typography } from '@mui/material'
import Sidebar from './Sidebar/Sidebar'
import Editor, { useMonaco } from '@monaco-editor/react';

import styles from './ScriptingView.module.sass'
import variables from '../../../variables.module.scss'

import MenuRoundedIcon from '@mui/icons-material/MenuRounded'
import MenuOpenRoundedIcon from '@mui/icons-material/MenuOpenRounded'
import CodeRoundedIcon from '@mui/icons-material/CodeRounded'
import DeleteRoundedIcon from '@mui/icons-material/DeleteRounded'
import SaveRoundedIcon from '@mui/icons-material/SaveRounded'

import 'monaco-themes/themes/GitHub Light.json'
import 'monaco-themes/themes/GitHub Dark.json'

import { LightDarkModeSwitch } from '../../UI/LightDarkModeSwitch/LightDarkModeSwitch'
import {
  EXAMPLE_SCRIPTS,
  TEMPLATE_SCRIPT_TITLES
} from '../../modals/ScriptingModal/ScriptingExamples'
import { FullscreenViewButton } from '../../UI/FullscreenView/FullscreenViewButton'
import Tooltip from '@mui/material/Tooltip'
import { ScriptingTemplates } from './ScriptingTemplates/ScriptingTemplates'
import { SidebarScript } from './SidebarScript/SidebarScript'
import { CircleDivider } from '../../UI/CircleDivider/CircleDivider'
import { Dropdown } from '../../UI/Dropdown/Dropdown'
import { useRecoilState, useSetRecoilState } from 'recoil'
import scriptsAtom from '../../../recoil/scripts'
import { getScripts } from './api/getScripts'
import { Script } from '../../modals/ScriptingModal/ScriptingTypes'
import { SidebarBanner } from './SidebarBanner/SidebarBanner'
import { putUpdateScript } from './api/putUpdateScript'
import { postCreateScript } from './api/postCreateScript'
import { deleteScript } from './api/deleteScript'
import { ActivateScriptButton } from './ScriptButtons/ActivateScriptButton'
import { DeactivateScriptButton } from './ScriptButtons/DeactivateScriptButton'
import { ScriptLogsButton } from './ScriptButtons/ScriptLogsButton'
import { postActivateScript } from './api/postActivateScript'
import { postDeactivateScript } from './api/postDeactivateScript'
import trafficViewTabAtom from '../../../recoil/trafficViewTab'
import { trafficViewTabs } from '../../../recoil/trafficViewTab/atom'
import { SidebarScriptGroup } from './SidebarScriptGroup/SidebarScriptGroup'
import FastForwardRoundedIcon from '@mui/icons-material/FastForwardRounded'
import StopCircleRoundedIcon from '@mui/icons-material/StopCircleRounded'
import CreateRoundedIcon from '@mui/icons-material/CreateRounded'
import scriptLogIndexOnLoadAtom from '../../../recoil/scriptLogIndexOnLoad'
import scriptIndexOnLoadAtom from '../../../recoil/scriptIndexOnLoad'
import scriptLogsAtom from '../../../recoil/scriptLogs'

export const ScriptingView = () => {
  const [sidebarOpen, setSidebarOpen] = useState(true)
  const [themesLoaded, setThemesLoaded] = useState(false)
  const [darkModeEnabled, setDarkModeEnabled] = useState(true)
  const [compactPadding, setCompactPadding] = useState(false)

  const [selectedScript, setSelectedScript] = useState<Script>(null)
  const [scriptTitle, setScriptTitle] = useState(Object.keys(TEMPLATE_SCRIPT_TITLES)[0])
  const [scriptText, setScriptText] = useState(EXAMPLE_SCRIPTS[0])
  const [scriptTitleChanged, setScriptTitleChanged] = useState(false)
  const [scriptChanged, setScriptChanged] = useState(false)
  const [templatesOpen, setTemplatesOpen] = useState(false)
  const [deleteDropdownOpen, setDeleteDropdownOpen] = useState(false)
  const [scriptsLoaded, setScriptsLoaded] = useState(false)

  const [activeScripts, setActiveScripts] = useState([])
  const [inactiveScripts, setInactiveScripts] = useState([])

  const [scriptIndexOnLoad, setScriptIndexOnLoad] = useRecoilState(scriptIndexOnLoadAtom)

  const [scripts, setScripts] = useRecoilState(scriptsAtom)
  const setScriptLogs = useSetRecoilState(scriptLogsAtom)
  const setTrafficViewTab = useSetRecoilState(trafficViewTabAtom)
  const setScriptLogIndexOnLoad = useSetRecoilState(scriptLogIndexOnLoadAtom)

  const deleteButtonRef = useRef(null)
  const firstRenderRef = useRef(true)

  const monaco = useMonaco()

  useEffect(() => {
    getScripts().then(scripts => {
      setScripts(scripts)
      setScriptsLoaded(true)
    })

    return () => {
      setScriptsLoaded(false)
      setScriptIndexOnLoad(null)
    }
  }, [])

  useEffect(() => {
    if (Object.keys(scripts).length === 0) {
      setActiveScripts([])
      setInactiveScripts([])
      return
    }

    const scriptEntries = Object.entries(scripts)

    const activeScripts: Script[] = []
    const inactiveScripts: Script[] = []

    scriptEntries.map(scriptVal => {
      const script = {...scriptVal[1]}
      script.index = parseInt(scriptVal[0])

      if (script.active) {
        activeScripts.push(script)
      } else {
        inactiveScripts.push(script)
      }
    })

    setActiveScripts(activeScripts)
    setInactiveScripts(inactiveScripts)

    if (!firstRenderRef.current || selectedScript !== null) {
      return
    }

    let index = 0
    if (scriptIndexOnLoad !== null) {
      index = scriptIndexOnLoad
    }

    const script: Script = {...scripts[index], index}

    setSelectedScript(script)
    setScriptTitle(script.title)
    setScriptText(script.code)

    firstRenderRef.current = false
  }, [scripts, scriptIndexOnLoad, selectedScript])

  useEffect(() => {
    if (monaco) {
      Promise.allSettled([
        import('monaco-themes/themes/GitHub Light.json')
          .then(data => {
            // @ts-expect-error: defineTheme should support arbitrary `string`
            // instead of `BuiltinTheme` = 'vs' | 'vs-dark' | 'hc-black' | 'hc-light'
            // `BuiltinTheme` type makes it impossible to set a custom theme &
            // avoid TS error.
            monaco.editor.defineTheme('github-light', data['default'])
          }),
        import('monaco-themes/themes/GitHub Dark.json')
          .then(data => {
            // @ts-expect-error: defineTheme should support arbitrary `string`
            // instead of `BuiltinTheme` = 'vs' | 'vs-dark' | 'hc-black' | 'hc-light'
            // `BuiltinTheme` type makes it impossible to set a custom theme &
            // avoid TS error.
            monaco.editor.defineTheme('github-dark', data['default'])
          })
      ]).then(() => setThemesLoaded(true))
    }
  }, [monaco])

  useEffect(() => {
    if (monaco && themesLoaded) {
      monaco.editor.setTheme(darkModeEnabled ? 'github-dark' : 'github-light')
    }
  }, [monaco, themesLoaded, darkModeEnabled])

  const handleSaveScriptChanges = () => {
    if (selectedScript === null) {
      postCreateScript(scriptTitle, scriptText).then(response => {
        getScripts().then(scripts => {
          setScripts(scripts)

          const createdScript = {...scripts[response.index]}
          createdScript.index = response.index

          setSelectedScript(createdScript)
          setScriptTitle(createdScript.title)
          setScriptText(createdScript.code)
          setScriptChanged(false)
        })
      })
    } else {
      putUpdateScript(selectedScript.index, scriptTitle, scriptText).then(() => {
        getScripts().then(scripts => setScripts(scripts))
        setScriptChanged(false)

        if (scriptTitleChanged) {
          setScriptTitleChanged(false)
        }
      })
    }
  }

  const handleDeleteScript = () => {
    deleteScript(selectedScript.index).then(() => {
      getScripts().then(scripts => {
        setScripts(scripts)

        setSelectedScript(null)
        setScriptTitle(Object.keys(TEMPLATE_SCRIPT_TITLES)[0])
        setScriptText(EXAMPLE_SCRIPTS[0])
      })
    }).then(() => setDeleteDropdownOpen(false))
  }

  const handleActivateScript = () => {
    postActivateScript(selectedScript.index).then(() => {
      getScripts().then(scripts => setScripts(scripts))
      setSelectedScript(script => {
        const updatedScript = {...script}
        updatedScript.active = true

        return updatedScript
      })
    })
  }

  const handleDeactivateScript = () => {
    postDeactivateScript(selectedScript.index).then(() => {
      getScripts().then(scripts => setScripts(scripts))
      setSelectedScript(script => {
        const updatedScript = {...script}
        updatedScript.active = false

        return updatedScript
      })

      setScriptLogs(scriptLogs => {
        const updatedScriptLogs = {...scriptLogs}
        delete updatedScriptLogs[selectedScript.index]
        return updatedScriptLogs
      })
    })
  }

  return (
    <div className={styles.ScriptingViewContainer}>
      <Box
        className={styles.FillAllSpaceContainer}
        padding={`0 ${compactPadding ? 8 : 16}px`}
        paddingTop={!compactPadding ? '10px' : 0}
      >
        <Box
          className={styles.FillAllSpaceContainer}
          position='relative'
          borderRadius='4px'
          boxShadow={variables.lightShadow}
        >
          <ScriptingTemplates
            darkModeEnabled={darkModeEnabled}
            open={templatesOpen}
            setOpen={setTemplatesOpen}
            onTemplateChosen={(index, title) => {
              setSelectedScript(null)
              setScriptTitle(title)
              setScriptText(EXAMPLE_SCRIPTS[index])
            }}
          />
          <Sidebar isOpen={sidebarOpen}>
            <Box
              boxSizing='border-box'
              padding='12px 16px'
              height='100%'
              width='100%'
              borderRight={`1px solid ${
                darkModeEnabled
                  ? variables.slateColor
                  : variables.lighterGrayColor
              }`}
              bgcolor={
                darkModeEnabled
                  ? variables.githubEditorBackgroundColorLight
                  : variables.mainBackgroundColor
              }
            >
              <Box
                boxSizing='border-box'
                display='flex'
                alignItems='center'
                gap='10px'
              >
                <Tooltip
                  title='Choose a script from our curated templates collection'
                  placement='top-start'
                  arrow
                >
                  <Button
                    color='success'
                    variant='contained'
                    size='small'
                    className='themeButton success'
                    fullWidth
                    onClick={() => setTemplatesOpen(true)}
                  >
                    <Typography
                      variant='body1'
                      fontSize={13}
                      fontFamily='Roboto'
                      fontWeight={700}
                      color='#ffffff'
                    >
                      Create from Template
                    </Typography>
                  </Button>
                </Tooltip>
                <Tooltip
                  title='Create an empty script'
                  placement='top-start'
                  arrow
                >
                  <IconButton
                    className={`themeButton ${
                      darkModeEnabled ? 'white' : 'black'
                    } secondary`}
                    size='small'
                    onClick={() => {
                      setSelectedScript(null)
                      setScriptTitle('Empty Script')
                      setScriptText(`// Nothing here, it's time to free your imagination!

// 🧑‍💻 See how Scripting benefits your experience in Kubeshark ⬇️:
// https://docs.kubeshark.co/en/automation_scripting
`)
                    }}
                  >
                    <CreateRoundedIcon sx={{ fontSize: '19px' }} />
                  </IconButton>
                </Tooltip>
              </Box>
              <Box className={styles.SidebarScripts}>
                {!scriptsLoaded && (
                  <SidebarBanner
                    title='Looking for scripts...'
                    icon={<CodeRoundedIcon htmlColor={variables.slateColor} sx={{ fontSize: '48px' }} />}
                    darkModeEnabled={darkModeEnabled}
                  />
                )}
                {scriptsLoaded && activeScripts.length === 0 && inactiveScripts.length === 0 && (
                  <SidebarBanner
                    title='Create your first script now!'
                    icon={<CodeRoundedIcon htmlColor={variables.slateColor} sx={{ fontSize: '48px' }} />}
                    darkModeEnabled={darkModeEnabled}
                  />
                )}
                {scriptsLoaded && activeScripts.length > 0 && (
                  <SidebarScriptGroup
                    title='Active Scripts'
                    icon={<FastForwardRoundedIcon sx={{ fontSize: '14px' }} />}
                  />
                )}
                {scriptsLoaded && activeScripts.map((script: Script) => {
                  return (
                    <SidebarScript
                      key={script.index}
                      darkModeEnabled={darkModeEnabled}
                      title={script.title}
                      active={script.active}
                      index={script.index}
                      selected={script.index === selectedScript?.index}
                      onSelection={(index) => {
                        const selectedScript = {...scripts[index]}
                        selectedScript.index = index

                        setSelectedScript(selectedScript)
                        setScriptTitle(selectedScript.title)
                        setScriptText(selectedScript.code)
                      }}
                    />
                  )
                })}
                {scriptsLoaded && inactiveScripts.length > 0 && (
                  <SidebarScriptGroup
                    title='Inactive Scripts'
                    icon={<StopCircleRoundedIcon sx={{ fontSize: '14px' }} />}
                  />
                )}
                {scriptsLoaded && inactiveScripts.map((script: Script) => {
                  return (
                    <SidebarScript
                      key={script.index}
                      darkModeEnabled={darkModeEnabled}
                      title={script.title}
                      active={script.active}
                      index={script.index}
                      selected={script.index === selectedScript?.index}
                      onSelection={(index) => {
                        const selectedScript = {...scripts[index]}
                        selectedScript.index = index

                        setSelectedScript(selectedScript)
                        setScriptTitle(selectedScript.title)
                        setScriptText(selectedScript.code)
                      }}
                    />
                  )
                })}
              </Box>
            </Box>
          </Sidebar>
          <Box
            className={styles.SidebarContent}
            sx={sidebarOpen ? { left: '300px !important' } : null}
          >
            {scriptChanged && (
              <ButtonBase
                component={Box}
                onClick={handleSaveScriptChanges}
                sx={{
                  position: 'absolute',
                  top: '80px',
                  left: '50%',
                  padding: '4px 10px',
                  backgroundColor: variables.warningColor,
                  borderRadius: '4px',
                  transform: 'translateX(-50%)',
                  zIndex: 1000
                }}
              >
                <span
                  style={{
                    fontFamily: 'Roboto',
                    fontSize: '14px',
                    fontWeight: 500,
                    color: variables.grayColor
                  }}
                >
                  <SaveRoundedIcon
                    sx={{
                      mr: '5px',
                      transform: 'translateY(2px)',
                      fontSize: '14px'
                    }}
                  />
                  Script changed. Click to save
                </span>
              </ButtonBase>
            )}
            <Box
              boxSizing='border-box'
              padding='8px 12px'
              width='100%'
              display='flex'
              alignItems='center'
              gap='10px'
              bgcolor={
                darkModeEnabled
                  ? variables.githubEditorBackgroundColorLight
                  : variables.mainBackgroundColor
              }
            >
              <IconButton onClick={() => setSidebarOpen(!sidebarOpen)}>
                {sidebarOpen ? (
                  <MenuOpenRoundedIcon htmlColor={variables.lightGrayColor} />
                ) : (
                  <MenuRoundedIcon htmlColor={variables.lightGrayColor} />
                )}
              </IconButton>
              <TextField
                variant='standard'
                placeholder='Enter script title'
                value={scriptTitle}
                type='string'
                style={{
                  flex: '1'
                }}
                inputProps={{
                  style: {
                    paddingTop: '6px',
                    color: darkModeEnabled
                      ? variables.headerBackgroundColor
                      : variables.grayColor,
                    fontSize: 18,
                    fontWeight: 600
                  }
                }}
                onChange={(event) => {
                  setScriptTitle(event.target.value)
                  setScriptTitleChanged(true)
                  setScriptChanged(true)
                }}
              />
              <Button
                color='primary'
                variant='contained'
                size='small'
                className='themeButton slate'
                startIcon={<SaveRoundedIcon sx={{ marginRight: '5px' }} />}
                onClick={handleSaveScriptChanges}
                disabled={!scriptChanged && selectedScript !== null}
              >
                <Typography
                  variant='body1'
                  fontSize={14}
                  fontFamily='Roboto'
                  fontWeight={500}
                  whiteSpace='nowrap'
                  color='#ffffff'
                >
                  Save Changes
                </Typography>
              </Button>
              <Dropdown
                open={deleteDropdownOpen}
                setOpen={setDeleteDropdownOpen}
                anchorElement={
                  <IconButton
                    ref={deleteButtonRef}
                    color='error'
                    onClick={() => setDeleteDropdownOpen(!deleteDropdownOpen)}
                  >
                    <DeleteRoundedIcon
                      htmlColor={variables.failureColor}
                      sx={{ fontSize: '24px' }}
                    />
                  </IconButton>
                }
                anchorRef={deleteButtonRef}
                placement='bottom-start'
                popperModifiers={[
                  {
                    name: 'offset',
                    options: {
                      offset: [0, 20]
                    }
                  }
                ]}
                growFrom='right top'
              >
                <Box
                  boxSizing='border-box'
                  padding='12px'
                  display='flex'
                  alignItems='center'
                  flexDirection='column'
                  gap='10px'
                  borderRadius='4px'
                  border={`1px solid ${
                    darkModeEnabled
                      ? variables.slateColor
                      : variables.lighterGrayColor
                  }`}
                  bgcolor={
                    darkModeEnabled
                      ? variables.githubEditorBackgroundColorLight
                      : variables.lightGrayBlueColor
                  }
                  maxHeight='400px'
                  overflow='auto'
                >
                  <p
                    style={{
                      margin: 0,
                      fontFamily: 'Roboto',
                      fontSize: '14px',
                      fontWeight: 500,
                      color: darkModeEnabled
                        ? variables.lighterGrayColor
                        : variables.slateColor,
                      whiteSpace: 'nowrap'
                    }}
                  >
                    Are you sure to delete this script?
                  </p>
                  <Box
                    boxSizing='border-box'
                    width='100%'
                    display='flex'
                    alignItems='center'
                    gap='10px'
                  >
                    <Button
                      color='error'
                      variant='contained'
                      size='small'
                      className='themeButton error'
                      startIcon={
                        <DeleteRoundedIcon sx={{ marginRight: '5px' }} />
                      }
                      onClick={handleDeleteScript}
                      sx={{
                        flex: 1
                      }}
                    >
                      <Typography
                        variant='body1'
                        fontSize={14}
                        fontFamily='Roboto'
                        fontWeight={500}
                        whiteSpace='nowrap'
                        color='#ffffff'
                      >
                        Delete
                      </Typography>
                    </Button>
                    <Button
                      variant='contained'
                      size='small'
                      className={`themeButton ${
                        darkModeEnabled ? 'slate' : 'light-gray'
                      }`}
                      onClick={() => {
                        setDeleteDropdownOpen(false)
                      }}
                    >
                      <Typography
                        variant='body1'
                        fontSize={14}
                        fontFamily='Roboto'
                        fontWeight={500}
                        whiteSpace='nowrap'
                        color='#ffffff'
                      >
                        Cancel
                      </Typography>
                    </Button>
                  </Box>
                </Box>
              </Dropdown>
              <CircleDivider
                bgColor={
                  darkModeEnabled
                    ? variables.slateColor
                    : variables.lighterGrayColor
                }
                mr='5px'
              />
              <LightDarkModeSwitch
                checked={darkModeEnabled}
                onChange={(event) => setDarkModeEnabled(event.target.checked)}
              />
              <FullscreenViewButton
                iconColor={
                  darkModeEnabled
                    ? variables.headerBackgroundColor
                    : variables.slateColor
                }
                bgColor='transparent'
                tooltipPlacement='top-start'
                onChange={(fullscreen) => setCompactPadding(fullscreen)}
              />
            </Box>
            <Editor
              height='100%'
              defaultLanguage='javascript'
              value={scriptText}
              onChange={(text) => {
                setScriptText(text)
                setScriptChanged(true)
              }}
            />
            <Box
              display='flex'
              alignItems='center'
              gap='15px'
              sx={{
                position: 'absolute',
                bottom: '20px',
                right: '20px'
              }}
              zIndex={900}
            >
              {selectedScript !== null && (
                selectedScript.active ? (
                  <DeactivateScriptButton darkModeEnabled={darkModeEnabled} onClick={handleDeactivateScript} />
                ) : (
                  <ActivateScriptButton darkModeEnabled={darkModeEnabled} onClick={handleActivateScript} />
                )
              )}
              {selectedScript !== null && (
                <ScriptLogsButton
                  darkModeEnabled={darkModeEnabled}
                  scriptActive={selectedScript.active}
                  onClick={() => {
                    setTrafficViewTab(trafficViewTabs.CONSOLE)
                    setScriptLogIndexOnLoad(selectedScript.index)
                  }} />
              )}
            </Box>
          </Box>
        </Box>
      </Box>
    </div>
  )
}
