import heapCacheMap from './singleton'

export function resetHeapCache(): void {
  delete heapCacheMap.__data__
  heapCacheMap.__data__ = {}
}

export function heapCacheString(str: string): string {
  if (!heapCacheMap.has(str)) {
    heapCacheMap.set(str, str)
  }
  return heapCacheMap.get(str)
}

function shouldCacheField(field: string, fieldsToCache: string[]): boolean {
  return fieldsToCache.some(f => f === field || f.startsWith(field + '.'));
}

export function heapCacheObject(obj, fieldsToCache: string[], isFirstLevel = true) {
  if (typeof obj === 'string') {
    return heapCacheString(obj);
  }
  if (Array.isArray(obj)) {
    return obj.map(item => heapCacheObject(item, fieldsToCache, false));
  }
  if (obj !== null && typeof obj === 'object') {
    const newObj = { ...obj };
    for (const key in newObj) {
      if (newObj[key] !== undefined) {
        if (typeof newObj[key] === 'string') {
          if (!isFirstLevel || shouldCacheField(key, fieldsToCache)) {
            newObj[key] = heapCacheString(newObj[key]);
          }
        } else if (typeof newObj[key] === 'object') {
          const nestedFields = fieldsToCache
            .filter(f => f.startsWith(key + '.'))
            .map(f => f.slice(key.length + 1));
          if (!isFirstLevel || shouldCacheField(key, fieldsToCache)) {
            newObj[key] = heapCacheObject(newObj[key], nestedFields, false);
          }
        }
      }
    }
    return newObj;
  }
  return obj;
}
