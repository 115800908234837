import React from 'react'

import { Box, Button, ButtonBase, Fade, Grid, Typography } from '@mui/material'

import { TEMPLATE_SCRIPT_TITLES } from '../../../modals/ScriptingModal/ScriptingExamples'
import ArrowBackRoundedIcon from '@mui/icons-material/ArrowBackRounded'

import styles from './ScriptingTemplates.module.sass'
import variables from '../../../../variables.module.scss'

interface ScriptingTemplatesProps {
  darkModeEnabled: boolean
  open: boolean
  setOpen: (open: boolean) => void
  onTemplateChosen: (index: number, title: string) => void
}

export const ScriptingTemplates: React.FC<ScriptingTemplatesProps> = ({
  darkModeEnabled,
  open,
  setOpen,
  onTemplateChosen
}) => {
  return (
    <Fade in={open}>
      <Box
        className={styles.ScriptingTemplatesOverlay}
        onClick={() => setOpen(false)}
        sx={{
          backgroundColor: darkModeEnabled ? 'rgba(53,58,67,0.61)' : 'rgba(184,184,184,0.61)'
        }}
      >
        <Box
          className={styles.ScriptingTemplatesContainer}
          onClick={(e) => e.stopPropagation()}
          sx={{
            backgroundColor: darkModeEnabled
              ? variables.githubEditorBackgroundColor
              : variables.mainBackgroundColor,
          }}
        >
          <Box
            position='relative'
            boxSizing='border-box'
            paddingBottom='16px'
            display='flex'
            alignItems='center'
            gap='15px'
          >
            <Button
              color='success'
              variant='contained'
              size='small'
              className={`themeButton ${darkModeEnabled ? 'white' : 'black'} secondary`}
              startIcon={<ArrowBackRoundedIcon sx={{ marginRight: '5px' }} />}
              onClick={() => setOpen(false)}
              sx={{
                position: 'absolute',
                left: 0
              }}
            >
              <Typography
                variant='body1'
                fontSize={13}
                fontFamily='Roboto'
                fontWeight={700}
              >
                Back
              </Typography>
            </Button>
            <Typography
              variant='h1'
              fontSize={16}
              fontFamily='Roboto'
              fontWeight={500}
              textAlign='center'
              color={darkModeEnabled ? variables.lighterGrayColor : variables.slateColor}
              sx={{ flex: 1 }}
            >
              Choose a script template
            </Typography>
          </Box>
          <Grid container spacing={1}>
            {Object.entries(TEMPLATE_SCRIPT_TITLES).map((template, i) => {
              return (
                <Grid item xs={3} key={template[0]}>
                  <ButtonBase
                    component={Box}
                    className={styles.ScriptingTemplate}
                    onClick={() => {
                      onTemplateChosen(i, template[0])
                      setOpen(false)
                    }}
                    sx={{
                      backgroundColor: darkModeEnabled ?
                        variables.githubEditorBackgroundColorLight : variables.lightGrayBlueColor,
                      color: darkModeEnabled ?
                        variables.lighterGrayColor : variables.slateColor
                    }}
                  >
                    {template[1]}
                    <p
                      style={{
                        margin: 0,
                        fontFamily: variables.textFontFamily,
                        fontSize: '13px',
                        fontWeight: 600,
                        textAlign: 'center',
                      }}
                    >
                      {template[0]}
                    </p>
                  </ButtonBase>
                </Grid>
              )
            })}
          </Grid>

        </Box>
      </Box>
    </Fade>
  )
}
