import React from "react";
import { EntryTableSection } from "../EntrySections/EntrySections";
import { CaptureError, CaptureErrorType } from "../../EntryListItem/Entry"

interface ManifestProps {
  error: CaptureError;
  color: string;
}

const ErrorSection: React.FC<ManifestProps> = ({ error, color }) => {
  let errorType = "";

  if (error !== null) {
    switch (error.type) {
    case CaptureErrorType.DissectionError:
      errorType = "Dissection error";
      break;
    case CaptureErrorType.ConnectionError:
      errorType = "Connection error";
      break;
    case CaptureErrorType.TimeoutError:
      errorType = "Timeout error";
      break;
    default:
      break;
    }
  }

  return (
    <EntryTableSection
      title="Error"
      color={color}
      arrayToIterate={[
        {
          name: "Error Type",
          displayedValue: errorType,
          value: error?.type,
          selector: "error.type",
        },
        {
          name: "Message",
          value: error?.msg,
          selector: "error.msg",
        },
      ]}
    />
  );
}

export default ErrorSection
