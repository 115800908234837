import React, { useEffect, useRef, useState } from 'react'
import { Utils } from '../../../helpers/Utils'

import variables from '../../../variables.module.scss'
import PercentageProgress from '../PercentageProgress/PercentageProgress'
import { Box, Tooltip, Typography } from '@mui/material'

interface ProgressLabeledProps {
  current: number
  total: number
}

const LABEL_OUTSIDE_THRESHOLD = 70

export const ProgressLabeled: React.FC<ProgressLabeledProps> = ({ current, total }) => {
  const percentage = current / total * 100
  const [labelRightSpacing, setLabelRightSpacing] = useState('')

  const capacityLabelRef = useRef<HTMLAnchorElement>(null)

  const calcRightSpacingPercentage = () => {
    return 100 - percentage + 2
  }

  const composeLabelRightSpacing = () => {
    if (capacityLabelRef.current == null) {
      return
    }

    const spacingPercentage = calcRightSpacingPercentage()

    if (spacingPercentage > LABEL_OUTSIDE_THRESHOLD) {
      return `calc(${spacingPercentage}% - ${capacityLabelRef.current.offsetWidth + 12}px)`
    }

    return `${spacingPercentage}%`
  }

  useEffect(() => {
    setLabelRightSpacing(composeLabelRightSpacing())
  }, [])

  return (
    <Box position='relative' width='100%' height='25px'>
      <Tooltip title='Remaining API calls' arrow followCursor>
        <PercentageProgress variant='determinate' value={percentage} />
      </Tooltip>
      <Typography
        ref={capacityLabelRef}
        variant='body2'
        fontSize={14}
        color={
          calcRightSpacingPercentage() > LABEL_OUTSIDE_THRESHOLD
            ? Utils.getColorByPercentage(percentage)
            : variables.mainBackgroundColor
        }
        fontFamily={variables.textFontFamily}
        style={{
          position: 'absolute',
          top: '4px',
          right: labelRightSpacing,
          whiteSpace: 'nowrap'
        }}
      >
        <strong>
          {Utils.humanReadableNumber(current)} / {Utils.humanReadableNumber(total)}
        </strong>
      </Typography>
    </Box>
  )
}
