import { HubBaseUrl } from '../consts'
import { heapCacheObject } from '../helpers/cacher/cacher'
import { useSetRecoilState } from 'recoil'
import targetedPodsAtom from '../recoil/targetedPods'
import { toast } from 'react-toastify'
import appliedPodRegexAtom from '../recoil/appliedPodRegex'
import appliedPodNamespacesAtom from '../recoil/appliedPodNamespaces'
import appliedPodExcludedNamespacesAtom from '../recoil/appliedPodExcludedNamespaces'
import appliedPodBpfOverrideAtom from '../recoil/appliedPodBpfOverride'

const useTargeting = () => {
  const setTargets = useSetRecoilState(targetedPodsAtom)

  const setPodRegex = useSetRecoilState(appliedPodRegexAtom)
  const setPodNamespaces = useSetRecoilState(appliedPodNamespacesAtom)
  const setPodExcludedNamespaces = useSetRecoilState(appliedPodExcludedNamespacesAtom)
  const setPodBpfOverride = useSetRecoilState(appliedPodBpfOverrideAtom)

  const fetchTargetedPods = () => {
    return fetch(
      `${HubBaseUrl}/pods/targeted`,
      {
        method: 'GET',
        headers: {
          'X-Kubeshark-Capture': 'ignore',
        },
      },
    )
      .then(response => response.text())
      .then(rawJson => rawJson ? JSON.parse(rawJson) : {})
      .then(data => {
        const targets = heapCacheObject(data.targets, ['metadata', 'spec', 'status'])
        setTargets(targets)
        return targets
      })
      .catch(err => console.error(err));
  }

  const postTargetSetting = (
    type: 'regex' | 'namespaces' | 'excluded-namespaces' | 'bpf',
    value: unknown,
    body: BodyInit,
    setError: (state: boolean) => void,
    setActive: (state: boolean) => void,
    set: (value: unknown) => void,
    setChanging: (state: boolean) => void,
    errorText: string
  ) => {
    fetch(
      `${HubBaseUrl}/pods/target/${type}`,
      {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
          'X-Kubeshark-Capture': 'ignore',
        },
        body
      }
    )
      .then((response) =>
        response.ok
          ? response
          : response.text().then((err) => Promise.reject(err))
      )
      .then(response => {
        if (response.status === 200) {
          setError(false)
          setActive(true)
          set(value)
          fetchTargetedPods()
        }
      })
      .catch(() => {
        toast.error(errorText, {
          theme: 'colored'
        });
        setError(true)
      }).finally(() => setChanging(false));
  }

  const fetchTargetSettings = () => {
    fetch(`${HubBaseUrl}/pods/target/settings`)
      .then(response => response.ok ? response : response.text().then(err => Promise.reject(err)))
      .then(response => response.json())
      .then(data => {
        const regex = data.regex.trim()
        setPodRegex(regex)

        const namespaces = data.namespaces.filter((value) => value.length > 0)
        setPodNamespaces(namespaces)

        const excludedNamespaces = data.excludedNamespaces.filter((value) => value.length > 0)
        setPodExcludedNamespaces(excludedNamespaces)

        const bpfOverride = data.bpfOverride.trim()
        setPodBpfOverride(bpfOverride)
      })
      .catch(err => {
        if (err.length > 0) {
          console.error(err)
        }
      });
  }

  return {
    fetchTargetedPods,
    postTargetSetting,
    fetchTargetSettings
  }
}

export default useTargeting
