import { useIdleTimer } from 'react-idle-timer'
import { toast } from 'react-toastify'
import { HubWsUrl } from '../consts'
import { appAuthEnabled, appOidcAuthTypeEnabled } from '../types/global'
import { getRefreshToken, getSessionToken } from '@descope/react-sdk'
import { useRecoilState } from 'recoil'
import streamingWsReadyState from '../recoil/streamingWsReadyState'
import { useWebSocketEventHandlers } from '../components/TrafficViewer/useWebSocketEventHandlers'
import useThrottling from './useThrottling'
import { useInterval } from '../helpers/interval'

export const THROTTLE_WS_STATE_CHANGE_MS = 1000

export let streamingWsConn: WebSocket;

interface WebsocketOptions {
  wsUrl: string
  withAuth: boolean
  withIdleTimer: boolean
  onMessage: (e) => void
  onError: (e) => void
}

const useStreamingWebSocket = (options: WebsocketOptions = {
  wsUrl: HubWsUrl,
  withAuth: true,
  withIdleTimer: true,
  onMessage: null,
  onError: null,
}) => {
  const [readyState, setReadyState] = useRecoilState(streamingWsReadyState)

  const { onMessage: onMessageDefault, onError: onErrorDefault } = useWebSocketEventHandlers()

  useInterval(() => {
    if (!streamingWsConn) {
      return
    }

    setReadyState(streamingWsConn.readyState)
  }, 500, true)

  const open = () => {
    const url = new URL(options.wsUrl)

    if (options.withAuth && appAuthEnabled() && appOidcAuthTypeEnabled()) {
      url.searchParams.set('session-token', getSessionToken());
      url.searchParams.set('refresh-token', getRefreshToken());
    }

    try {
      streamingWsConn = new WebSocket(url)
      streamingWsConn.onmessage = options.onMessage ?? onMessageDefault
      streamingWsConn.onerror = options.onError ?? onErrorDefault
    } catch (e) {
      console.error(e)
    }
  }

  const send = (data: string | ArrayBufferLike | Blob | ArrayBufferView) => {
    if (streamingWsConn && streamingWsConn.readyState === WebSocket.OPEN) {
      streamingWsConn.send(data)
    }
  }

  const close = () => {
    if (streamingWsConn && streamingWsConn.readyState === WebSocket.OPEN) {
      streamingWsConn.close(1000)
    }
  }

  const reopen = useThrottling(() => {
    close()
    setTimeout(() => {
      open()
    }, 500)
  }, THROTTLE_WS_STATE_CHANGE_MS)

  const toggle = useThrottling(() => {
    if (!streamingWsConn) {
      return
    }

    if (streamingWsConn.readyState === WebSocket.OPEN) {
      close()
    } else {
      open()
    }
  }, THROTTLE_WS_STATE_CHANGE_MS)

  useIdleTimer({
    timeout: 5 * 60 * 1000,
    onIdle: () => {
      if (streamingWsConn && streamingWsConn.readyState === WebSocket.OPEN) {
        close()
        toast.warning("Connection is closed because of inactivity!", {
          theme: "colored",
          autoClose: 3000,
        });
      }
    },
    disabled: !options.withIdleTimer
  })

  return {
    open,
    send,
    close,
    reopen,
    toggle,
    readyState,
    setReadyState,
  }
}

export default useStreamingWebSocket
