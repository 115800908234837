import { LocalStorageKey } from '../consts'
import { useSearchParams } from 'react-router-dom'
import { useSetRecoilState } from 'recoil'
import queryAtom from '../recoil/query'
import queryBuildAtom from '../recoil/queryBuild'

interface QuerySave {
  saveQuery: () => Promise<boolean>
}

const useQuerySave = (): QuerySave => {
  const setQuery = useSetRecoilState(queryAtom)
  const setQueryBuild = useSetRecoilState(queryBuildAtom)

  const [searchParams] = useSearchParams()

  const saveQuery = (): Promise<boolean> => {
    return new Promise((resolve) => {
      const querySearchParam = searchParams.get('q')

      if (querySearchParam === null) {
        resolve(true)
        return
      }

      setQueryBuild(querySearchParam)
      setQuery(querySearchParam)

      localStorage.setItem(LocalStorageKey.ShouldRestoreQuery, querySearchParam)
      resolve(true)
    })
  }

  return { saveQuery }
}

export default useQuerySave
